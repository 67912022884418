import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreDetails } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-my-dashboard',
    templateUrl: './my-dashboard.component.html',
    styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit {
    storeid: string = "";
    userid: any;
    offers: any;
    Grid: number = 1;
    fname: any;
    lname: any;
    gender: any;
    phonenumber: any;
    storeDetails: StoreDetails = {};
    hasoffers: boolean = false;
    constructor(private api: ApiService, private route: Router) { }

    ngOnInit(): void {
        this.storeDetails = {}
        
        // this.storeid = localStorage.getItem("storeid")
        var accesskey = localStorage.getItem("accesskey");
        this.userid = localStorage.getItem("userid");
        this.gender = localStorage.getItem("gender");
        this.fname = localStorage.getItem("firstname");
        this.lname = localStorage.getItem("lastname");
        var offerstatus: string = "LIVE";
        this.api.getStoreDetails(this.userid).subscribe((result: any) => {
            // console.log(result);
            this.storeDetails = result.success[0];
            if (result.success.length != 0) {
                
                localStorage.setItem("storeid", this.storeDetails.storeid)
            }
        }, error => {
            if (error.error.code == 400) {
                // Swal.fire({
                //     toast: false,
                //     icon: 'error',
                //     title: error.error.message,
                //     showConfirmButton: false,
                //     timerProgressBar: true,
                //     width:'500px',
                //     timer: 2000
                //   })
                window.alert(error.error.message);
            }




            if (error.error.code == 401) {
                window.alert(error.error.message + " Please Login!");
                localStorage.clear();
                this.route.navigate(['']);
            }
        })
        if(localStorage.getItem("isLoggedin")=="true"){
        this.storeid = localStorage.getItem("storeid");
        console.log(this.storeid);
    }else{
        this.route.navigate([""]);
    }
        
        if (this.storeid != "") {
            console.log(true);
            
            this.api.GetOffersLogin(this.storeid, accesskey, this.userid, offerstatus).subscribe((result: any) => {
                this.offers = result.success;
            }, error => {
                if (error.error.code == 400) {
                    // Swal.fire({
                    //     toast: false,
                    //     icon: 'error',
                    //     title: error.error.message,
                    //     showConfirmButton: false,
                    //     timerProgressBar: true,
                    //     width:'500px',
                    //     timer: 2000
                    // })
                    window.alert(error.error.message);
                }
                if (error.error.code == 401) {
                     window.alert(error.error.message + " Please Login!");
                    localStorage.clear();
                    this.route.navigate(['']);
                }
            });
        }
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title5.jpg`,
            title: ``,
        }
    ]
}