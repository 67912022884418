import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import categoryPageJson from 'src/assets/config.json/offerji.json';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
    selector: 'app-categories-page',
    templateUrl: './categories-page.component.html',
    styleUrls: ['./categories-page.component.scss']
})
export class CategoriesPageComponent implements OnInit {

    categoryPageJson : any = categoryPageJson.categoryPageComponent
    categories: any;
    constructor(private api: ApiService) { }

    ngOnInit(): void {
        this.api.getcategories().subscribe((result: any) => {
            this.categories = result.success
            console.log(this.categories);
        }, error => {
            if(error.error.code == 400){
                window.alert(error.error.message);
            }
        })
    }

    customOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        center: true,
        dots: false,
        autoHeight: true,
        autoWidth: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        }
    }

   

}