<div class="profile-authentication-area ptb-1">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>{{loginData.loginName}}</h2>
                    <form [formGroup]="loginForm">
                        <p style="color: red;">{{errmsg}}</p>
                        <div class="form-group">
                            <label>{{loginData.email}}</label>
                            <input type="text" formControlName="useremail" class="form-control">
                            <div class="text-danger" *ngIf="!loginForm.controls['useremail'].valid &&
                            loginForm.controls['useremail']?.touched">
                                {{loginData.email_placeholder}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{loginData.password}}</label>
                            <input type="password" formControlName="password" class="form-control">
                        </div>
                        <div class="text-danger" *ngIf="!loginForm.controls['password'].valid &&
                        loginForm.controls['password']?.touched">
                            {{loginData.password_placeholder}}
                        </div>
                        <!-- <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="test2">
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/" class="lost-your-password">Lost your password?</a>
                            </div>
                        </div> -->
                        <a style="margin-top: 30px;color: #FB3939;" class="btn" data-toggle="modal"
                            (click)="requestForm()" data-target="#exampleModal">{{loginData.forgotPassword}}</a>
                        <button type="submit" (click)="login(this.loginForm.value)">{{loginData.loginButton}}</button>

                    </form>
                    <div class="text-center" style="padding-top:10px">
                        <a href="https://play.google.com/store/apps/details?id=com.gosimple.lite" target="_blank"><img
                                style="height: 70px;" src="{{loginData.googlePlayImg}}" alt="sdf"></a>

                        <a href="https://play.google.com/store/apps/details?id=com.gosimple.lite" target="_blank"><img
                                style="height: 70px;" src="{{loginData.appStoreImg}}" alt="sdf"></a>
                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form [formGroup]="requestOtpForm" *ngIf="requestform && requestOtpForm">
                                        <div class="form-group">
                                            <label> {{loginData.modalEmail}} </label>
                                            <input type="email" formControlName="email" class="form-control"
                                                placeholder="{{loginData.modalEmailPlaceholder}}">
                                            <button (click)="sendotp(this.requestOtpForm)">Send OTP</button>
                                        </div>
                                    </form>
                                    <form [formGroup]="ValidateForm" *ngIf="validateotp && ValidateForm">
                                        <label for="" *ngIf="errormessage == ''">OTP Sent to your email</label>
                                        <label for="" style="color: red;">{{errormessage}}</label>
                                        <div class="form-group">
                                            <label>{{loginData.modalEmail}} </label>
                                            <input type="email" formControlName="email" class="form-control"
                                                placeholder="{{loginData.modalEmailPlaceholder}}" read>

                                        </div>
                                        <div class="form-group">
                                            <label>{{loginData.otp}} </label>
                                            <input type="otp" formControlName="otp" class="form-control"
                                                placeholder="{{loginData.otpPlaceholder}}">
                                        </div>
                                        <div class="form-group">
                                            <label>{{loginData.password}}</label>
                                            <input type="password" formControlName="password" class="form-control"
                                                placeholder="{{loginData.password}}">
                                        </div>
                                        <div class="form-group">
                                            <label>{{loginData.confirmPassword}} </label>
                                            <input type="password" formControlName="confirmpassword"
                                                class="form-control" placeholder="{{loginData.confirmPasswordPlaceholder}}">
                                        </div>
                                        <button (click)="validateOtp(this.ValidateForm)">{{loginData.sendOtp}} </button>
                                    </form>

                                    <div class="showmessage" *ngIf="showmessage">
                                        <p style="font-size: 25px;color: green;">{{successmessage}}</p>

                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{loginData.closeBtn}}</button>
                                    <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Delete</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">

                <div class="wrapper">
                    <owl-carousel-o [options]="customOptions">

                        <ng-container *ngFor="let slide of loginData.slides">
                            <ng-template class="slide" carouselSlide [id]="slide.id">
                                <img [src]="slide.img" [alt]="slide.id" [title]="slide.id">
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>