import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ContactForm, requestOtp, StoreDetails, UserDetails, ValidateOtp } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  loginSubject: BehaviorSubject<any> = new BehaviorSubject<any>({ loggedIn: false })

  baseUrl = 'https://offerji.com:800';
  // baseUrl = 'https://exp.offerji.com:800';
  constructor(private http: HttpClient) { }

  getDeals(categoryName: string, storeid: string, offertype: any) {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/publicoffers?category="+categoryName);
    var url = '/api/v1/offers'
    url += '?category=' + categoryName
    url += '&storeid=' + storeid
    url += '&offertype=' + offertype;
    url += '&offerstatus=LIVE'
    url += '&storestatus=ACTIVE'

    return this.http.get<any>(this.baseUrl + url)
  }
  getcategories() {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/newcategories");
    var url = '/api/v1/categories'
    return this.http.get<any>(this.baseUrl + url);
  }
  getStores(category: string, location: string) {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/newstores?category="+category+"&location="+location);
    var url = '/api/v1/fetchstorebyalphabet?category=' + category + '&location=' + location + '&storestatus=ACTIVE'
    return this.http.get<any>(this.baseUrl + url);
  }
  Offers(storeid: any, offerid: any) {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/newofferdetails?storeid="+storeid+"&offerid="+offerid+"&requestfrom=web&offerstatus=LIVE");
    var url: string;
    url = '/api/v1/offers?offerid=' + offerid + '&storeid=' + storeid + '&offerstatus=LIVE'
    return this.http.get<any>(this.baseUrl + url);
  }
  OffersPreview(storeid: any, offerid: any) {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/newofferdetails?storeid="+storeid+"&offerid="+offerid+"&requestfrom=web&offerstatus=LIVE");
    var url: string;
    url = '/api/v1/offers?offerid=' + offerid + '&storeid=' + storeid + '&offerstatus=LIVE,DRAFT,PRIVATE'
    return this.http.get<any>(this.baseUrl + url);
  }
  // contact(data: any) {
  //   return this.http.post(this.baseUrl + '/api/user/v1/newcontactdetails', data);
  // }
  LoginUser(postData: any) {
    return this.http.post<any>(this.baseUrl + '/api/v1/user/login', postData);
  }
  GetOffersLogin(storeid: any, accesskey: any, userid: any, offerstatus: any) {
    var access_token = localStorage.getItem("accesskey")
    var tokenheaders = new HttpHeaders({
      Authorization: `key=${access_token}`
    });
    var url: string
    url = "/api/v1/offers"
    url += "?storeid="+storeid
    url += "&userid="+userid
    url += "&offerstatus="+offerstatus
    return this.http.get<any>(this.baseUrl+url,{headers: tokenheaders})
    // return this.http.get<any>(this.baseUrl + "/api/v1/offers?storeid=" + storeid + "&accesskey=" + accesskey + "&accessuserid=" + userid + "&requestfrom=web&offerstatus=LIVE");
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/searchoffers");
  }
  getStoreDetails(accessuserid: string) {
    return this.http.get<any>(this.baseUrl + "/api/v1/stores?accessuserid=" + accessuserid);
  }
  storeDetailsWithid(storeid: any) {
    var url: string;
    url = "/api/v1/stores"
    url += "?storeid="+storeid
    return this.http.get<any>(this.baseUrl+url)
    // return this.http.get<any>(this.baseUrl + "/api/user/v1/newoffers?storeid=" + storeid + "&offerstatus=LIVE")
  }

  getStoreWithOutAlphabet(storeid: any) {
    var url: string;
    url = '/api/v1/stores?storeid=' + storeid + '&storestatus=ACTIVE'
    return this.http.get<any>(this.baseUrl + url)
  }

  getLocations() {
    // return this.http.get<any>(this.baseUrl+"/api/user/v1/storelocations")
    var url = '/api/v1/storelocation'
    return this.http.get<any>(this.baseUrl + url)
  }

  getUserDetails(accessuserid: any) {
    var access_token = localStorage.getItem("accesskey")
    var tokenheaders = new HttpHeaders({
      Authorization: `key=${access_token}`
    });
    var url = "/api/v1/user";
    url += "?accessuserid="+accessuserid
    return this.http.get<any>(this.baseUrl + url,{headers:tokenheaders});
  }

  updateUser(editData: UserDetails){
    var access_token = localStorage.getItem("accesskey")
    var tokenheaders = new HttpHeaders({
      Authorization: `key=${access_token}`
    });
    var url = "/api/v1/user"
    return this.http.put<any>(this.baseUrl+url,editData,{headers: tokenheaders});
  }

  updateStore(editStore: StoreDetails){
    var access_token = localStorage.getItem("accesskey");
    var tokenheaders = new HttpHeaders({
      Authorization: `key=${access_token}`
    });
    var url = "/api/v1/stores"
    return this.http.put<any>(this.baseUrl+url,editStore,{headers: tokenheaders})
  }

  getOfferItems(offerid: any){
    var url: string;
    url = "/api/v1/offeritem"
    url +="?offerid="+offerid
    return this.http.get<any>(this.baseUrl+url)
  }

  contactus(contact: ContactForm){
    var url: string = "";
    url += "/api/v1/contactus"
    return this.http.post<any>(this.baseUrl+url, contact);
  }

  deleteStore(storeid: string){
    var access_token = localStorage.getItem("accesskey");
    var tokenheaders = new HttpHeaders({
      Authorization: `key=${access_token}`
    });
    var url: string = "";
    url += "/api/v1/stores";
    url += "?id="+storeid
    return this.http.delete<any>(this.baseUrl+url,{headers: tokenheaders})
  }

  requestOtp(otpdata: requestOtp){
    var url: string = "";
    url += "/api/v1/user/request/otp"
    return this.http.post<any>(this.baseUrl+url, otpdata);
  }

  validateOtp(validatedata: ValidateOtp){
    var url: string = "";
    url += "/api/v1/user/resetpassword";
    return this.http.post<any>(this.baseUrl+url, validatedata);
  }

  getBlogs(storyid: any){
    var url: string = "";
    url += "/api/v1/stories";
    url += "?store_id="+storyid
    return this.http.get<any>(this.baseUrl+url);
  }
}