<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="deals-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-12" *ngIf="storedetails">
                <aside class="widget-area">
                    <div class="widget widget_about_store">
                        <h3 class="widget-title">{{storeDetailsJson.allAboutStore}}</h3>
                        <div class="content">
                            <img src="{{storeDetailsJson.image}}" alt="image" *ngIf="storedetails.storelogo == ''">
                            <img style="width: 135px;border-radius: 15px;" src="{{storedetails.storelogo}}" alt="image"
                                *ngIf="storedetails.storelogo != ''">
                            <!-- <p>Expound the actual teachings of the great explorer of the truth, the master-builder of human.</p> -->
                            <h4>{{storedetails.storename}}</h4>
                            <p>{{storedetails.storedescription}}</p>
                            <a style="text-decoration:underline ;"
                                href="tel: {{storedetails.phonenumber}}"><span><b>{{storedetails.phonenumber}}</b></span></a>
                        </div>
                    </div>
                    <div class="widget ">
                        <h3 class="widget-title">{{storeDetailsJson.stayConnected}}</h3>
                        <ul class="social-links">
                            <li><a href="{{storedetails.facebooklink}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="{{storedetails.linkedinlink}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="{{storedetails.facebooklink}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="{{storedetails.instagramlink}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li *ngIf="storedetails.weburl != ''"><a href="{{storedetails.weburl}}" target="_blank"><i class="bx bxl-chrome"></i></a></li>
                            <li *ngIf="storedetails.weburl == ''" style="display: none;"><a href="{{storedetails.weburl}}" target="_blank"><i class="bx bxl-chrome"></i></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_looking_for">
                        <h3 class="widget-title">{{storeDetailsJson.dealsOrCoupons}}</h3>
                        <ul>
                            <li>
                                <input type="radio" id="all" name="lookingFor" (change)="getOfferType($event)" value=""
                                    checked>
                                <label for="all">{{storeDetailsJson.all}}</label>
                            </li>
                            <li>
                                <input type="radio" id="dealsOnly" name="lookingFor" (change)="getOfferType($event)"
                                    value="Campaign">
                                <label for="dealsOnly">{{storeDetailsJson.campaign}}</label>
                            </li>
                            <li>
                                <input type="radio" id="couponsOnly" name="lookingFor" (change)="getOfferType($event)"
                                    value="Catalogue">
                                <label for="couponsOnly">{{storeDetailsJson.Catalogue}}</label>
                            </li>
                        </ul>
                    </div>
                </aside>
            </div>
            <div class="col-xl-9 col-lg-8 col-md-12">
                <div class="text-center" *ngIf="offers?.length == 0">
                    <img src="assets/offerji/nooffers_found_.png" alt="" style="object-fit: scale-down;width: 56%;">
                    <div class="text-danger">
                        <!-- <h2>{{storeDetailsJson.noOffersFound}}</h2> -->

                    </div>
                </div>
                <div *ngIf="offers?.length !=0">
                    <div class="row">

                        <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6" style="margin-bottom: 30px;"
                            *ngFor="let Content of offers | paginate: { itemsPerPage: 12, currentPage: dealsGrid }">
                            <div>
                                <!-- <div class="single-deals-box">
                                    <div class="card-header deals-image">
                                        <a routerLink="/viewOffers/{{Content.offerid}}/{{storedetails.storeid}}"
                                            class="d-block">
                                            <img src="assets/img/deals/deals1.jpg" alt="image"
                                                *ngIf="Content.offercover == ''">
                                            <img style="height: 190px;width:100%;object-fit:contain;"
                                                src="{{Content.offercover}}" alt="image"
                                                *ngIf="Content.offercover != ''">
                                        </a>
                                        <a routerLink="/{{Content.detailsLink}}" class="show-coupon-code"
                                            target="_blank" ngxClipboard [cbContent]="[Content.couponCode]"
                                            [container]="container" tooltip="Click to Copy" placement="right"
                                            show-delay="100" *ngIf="Content.couponCode">Show Coupon Code</a>
                                    </div>
                                    <div class="card-body deals-content">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rating">

                                            </div>
                                            <h6 class="text-center"><a
                                                    routerLink="/viewOffers/{{Content.offerid}}/{{storedetails.storeid}}">{{Content.offertitle}}</a>
                                            </h6>
                                            <div class="align-items-center justify-content-between text-center"
                                                style="width: 100%;">
                                                <a routerLink="/viewOffers/{{Content.offerid}}/{{storedetails.storeid}}"
                                                    class="btn details-btn"
                                                    style="background-color: #C44118;color: #FFFFFF">View
                                                    Details</a>
                                            </div>
                                        </div>
                                        <div class="box-footer">

                                        </div>
                                    </div>
                                </div> -->


                                <div class="card">
                                    <div class="img-wrapper">
                                        <img class="inner-img" src="assets/img/deals/deals1.jpg" alt="image"
                                            *ngIf="Content.offercover == ''">
                                        <img class="inner-img" style="width:100%;object-fit:contain;"
                                            src="{{Content.offercover}}" alt="image" *ngIf="Content.offercover != ''">
                                    </div>

                                    <div class="card-body">
                                        <h6 class="text-center ellips" tooltip="{{Content.offertitle}}"><a
                                                routerLink="/viewOffers/{{Content.offerid}}/{{storedetails.storeid}}">{{Content.offertitle}}</a>
                                        </h6>
                                        <div class="align-items-center justify-content-between text-center"
                                            style="width: 100%;">
                                            <a routerLink="/viewOffers/{{Content.offerid}}/{{storedetails.storeid}}"
                                                class="btn details-btn"
                                                style="background-color: #C44118;color: #FFFFFF">{{storeDetailsJson.ViewDetails}}</a>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <div *ngIf="offers?.length == 0">
                                <p style="color: red;">{{storeDetailsJson.noOffersFound1}}</p>
                            </div>
                        </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="pagination-area text-center">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="dealsGrid = $event">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="offers?.length == 0">
                        <p style="color: red;">{{storeDetailsJson.noOffersShow}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>