import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginData, LoginResponce, requestOtp, ValidateOtp } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import loginData from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-profile-authentication-page',
    templateUrl: './profile-authentication-page.component.html',
    styleUrls: ['./profile-authentication-page.component.scss']
})
export class ProfileAuthenticationPageComponent implements OnInit {
    loginData: any = loginData.login;
    loginForm: FormGroup;
    postData: LoginData = {};
    errmsg: string = "";
    submitted: boolean = false
    loginresponce: LoginResponce = {};
    requestOtpForm: FormGroup;
    requestform: boolean = false;
    validateotp: boolean = false;
    ValidateForm: FormGroup;
    otpemail: any;
    otpData: requestOtp = {};
    validateotpdata: ValidateOtp = {};
    showmessage: boolean = false;
    successmessage: string = "";
    errormessage: string = "";
    images: any;
    slides: { img: string; }[];







    constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

    ngOnInit(): void {

        this.loginForm = this.formBuilder.group({
            "useremail": ['', [Validators.required]],
            "password": ['', Validators.required]
        })
        this.requestOtpForm = this.formBuilder.group({
            email: ["", Validators.required]
        })

    }


    login(data: any) {
        if (this.loginForm.valid) {
            this.postData.useremail = data.useremail;
            this.postData.password = data.password;
            this.postData.requestfrom = "offerji_web";
            this.api.LoginUser(this.postData).subscribe((result: any) => {
                console.log(result);

                if (result.code == 200) {
                    this.api.loginSubject.next({ loggedIn: true })
                    this.loginresponce = result.success
                    console.log(this.loginresponce);
                    localStorage.setItem('isLoggedin', 'true');
                    // localStorage.setItem()       
                    localStorage.setItem('storeid', this.loginresponce.storeid)
                    localStorage.setItem('userid', this.loginresponce.accessuserid)
                    localStorage.setItem('accesskey', this.loginresponce.accesskey);
                    localStorage.setItem('firstname', this.loginresponce.firstname);
                    localStorage.setItem('useremail', this.loginresponce.useremail);
                    localStorage.setItem("lastname", this.loginresponce.lastname);
                    localStorage.setItem("gender", this.loginresponce.gender);
                    localStorage.setItem("phonenumber", this.loginresponce.phonenumber);

                    // localStorage.setItem("")
                    this.router.navigate(['/dashboard']);
                } else {
                    this.errmsg = "Invalid Username or password";
                }
            }, error => {
                if (error.error.code == 400) {
                    // window.alert(error.error.message);
                    this.errmsg = error.error.message;
                }
            })
        } else {
            this.submitted = true;
            Object.keys(this.loginForm.controls).forEach(field => {
                const control = this.loginForm.get(field);
                control?.markAsTouched({ onlySelf: true });
            });

        }

    }

    requestForm() {
        this.requestform = true;
        this.validateotp = false;
    }

    sendotp(form: any) {
        if (form.valid) {
            this.otpData.email = form.value.email
            this.api.requestOtp(this.otpData).subscribe((result: any) => {
                if (result.code == 200) {
                    this.otpemail = form.value.email

                    this.requestform = false;
                    this.showmessage = false;
                    this.validateotp = true;
                    console.log(result);
                    this.validateformactivate()


                }
            }, error => {
                if (error.error.code == 400) {
                    window.alert(error.error.message);
                }
            })
        }
    }

    validateformactivate() {
        this.ValidateForm = this.formBuilder.group({
            email: [this.otpemail, Validators.required],
            otp: ["", Validators.required],
            password: ["", Validators.required],
            confirmpassword: ["", Validators.required],
        })
    }

    validateOtp(form: any) {

        if (form.valid && form.value.otp.length == 6) {
            this.validateotpdata = form.value
            this.api.validateOtp(this.validateotpdata).subscribe((result: any) => {
                if (result.code == 200) {
                    this.validateotp = false;
                    this.requestform = false;
                    this.showmessage = true;
                    this.successmessage = result.message;
                    this.errormessage = "";
                }
            }, error => {
                if (error.error.code == 400) {

                    this.successmessage = "";
                    this.errormessage = error.error.message;
                }
            })

        }
    }

    // ................................


    // login(data:any)
    // {
    //   const postData:JSON = <JSON><unknown>{
    //       "requestfrom":data.web,
    //     "useremail": data.email,
    //     "password": data.password,



    //   }
    //   console.log(postData);

    //   this.api.LoginUser(postData).subscribe((result:any)=>
    //   {
    //     if(result.code == '200')
    //     {
    //      localStorage.setItem('storeid',result.storeid)
    //      localStorage.setItem('userid',result.success.userid)
    //      localStorage.setItem('accesskey',result.success.accesskey);
    //       this.router.navigate(["/dashboard"]);





    //     }
    //     else if(result.code == '400'){

    //     }
    //     console.log(result)
    //   })
    //   console.log(data);

    // }


    customOptions: OwlOptions = {
        loop: true,
        autoplay: true,
        center: true,
        dots: false,
        autoHeight: true,
        autoWidth: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        }
    }

}

