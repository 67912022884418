import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import storePageJson from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-stores-page',
    templateUrl: './stores-page.component.html',
    styleUrls: ['./stores-page.component.scss']
})
export class StoresPageComponent implements OnInit {

    storePageJson : any = storePageJson.storePageComponent
    category: string = "";
    location: any = "";
    categories: any;
    storename: [""];
    locations: any;
    categoryname: string;
    locationname: any;
    StoreAlphabeticKey: any;
    constructor(private api: ApiService, private route: ActivatedRoute) { }
    Allstores: any[] = []
    storeAlpha: any[] = []
    storeGrid: number = 1;
    ngOnInit(): void {

        console.log(this.route.snapshot.paramMap.get('location'));
        console.log(this.route.snapshot.paramMap.get("category"));

        
        

        this.api.getcategories().subscribe((result: any) => {
            this.categories = result.success;
            // console.log(this.categories);
            
        }, error => {
            if(error. error.code == 400){
                window.alert(error.error.message);
            }
        })

        this.api.getLocations().subscribe((result:any) => {
            this.locations = result.success;
        }, error => {
            if(error.error.code == 400){
                window.alert(error.error.message);
            }
        })
        
        
        if(this.route.snapshot.paramMap.get('location')!=null && this.route.snapshot.paramMap.get("category")!=null){
            this.category = this.route.snapshot.paramMap.get("category");
            this.location = this.route.snapshot.paramMap.get("location");
            this.categoryname = this.category;
            this.locationname = this.location;
            if(this.category == "All Categories"){
               
                
                this.category = "";
            }
            if(this.location == "All Locations"){
                
                this.location = "";
            }
        }
        this.Storesapi(this.category, this.location);

    }
    Storesapi(category, location) {
        this.api.getStores(category, location).subscribe((result: any) => {
            console.log(result.success);
            this.storeAlpha = result.success;
            this.render({ StoreAlphabeticKey: 'All' })
            // console.log(this.Allstores);
        }, error => {
            if(error.error.code == 400){
                window.alert(error.error.message);
            }
        })
    }
    render(data: any) {
        this.Allstores = []
        this.StoreAlphabeticKey = data.StoreAlphabeticKey
        if (data.StoreAlphabeticKey === 'All') {
            for (let e of this.storeAlpha) {
                this.Allstores = this.Allstores.concat(e.Stores)
                // console.log(e);

            }


        }
        else {
            this.Allstores = data.Stores
            // console.log(this.Allstores);

        }


    }
    selectCategory(data){
        // console.log(data.target.value);
        this.category = data.target.value;
        console.log(this.category);
        
        if(this.category == "All Categories"){
            this.category = "";
        }
        this.Storesapi(this.category,this.location);
        // console.log("yes");
        
    }

    selectLocation(data){
        // console.log(data.target.value);
        this.location = data.target.value;
        if(this.location == "All Locations"){
            this.location = "";
        }
        this.Storesapi(this.category,this.location);
        // console.log("yes");
        
    }

  
}