import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OfferItems, StoreDetails, } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-deals-page',
    templateUrl: './deals-page.component.html',
    styleUrls: ['./deals-page.component.scss']
})
export class DealsPageComponent implements OnInit {

    isLoggedin: boolean = false;
    dealsGrid: number = 1;
    offerid: any;
    storeid: any;
    offers: any;
    offerEnds: any;
    items: any;
    elem: any;
    storeDetails: StoreDetails = {};

    constructor(private route: ActivatedRoute, private api: ApiService, private router: Router) { }


    ngOnInit(): void {



        if (localStorage.getItem("isLoggedin") == null && localStorage.getItem("isLoggedin") != "true") {
            this.isLoggedin = false;
        } else {
            this.isLoggedin = true;
        }

        console.log(this.isLoggedin);



        this.elem = document.documentElement
        this.offerid = this.route.snapshot.paramMap.get('offerid');
        this.storeid = this.route.snapshot.paramMap.get('storeid');

        this.api.Offers(this.storeid, this.offerid).subscribe((result: any) => {
            this.offers = result.success[0];
            console.log(this.offers);
            
            // this.offerEnds = result.success.EndsIn
            // this.storeDetails = result.success.Stores;

            // this.items = result.success.Items;


        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }
        });


        this.api.getOfferItems(this.offerid).subscribe((result: any) => {
            this.items = result.success;
            console.log(this.items);
        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }
        });




    }

    buyNowLink(value: any) {
        window.open(value);
        console.log(value);
        
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title5.jpg`,
            title: ``
        }
    ]
   
}