<div class="container">
    <div class="dashboard-stats">
        <a class="viewOffers-btn" routerLink="/">{{allOffersJson.goHome}}</a>
        <div class="row">
            <div class="col-lg-4 offer"
                *ngFor="let data of allOffers | paginate: { itemsPerPage: 6, currentPage: Grid }">
                <div class="card">
                    <div class="card-body card-head-img">
                        <img *ngIf="data.offercover == '' " src="{{allOffersJson.img}}" alt="image">
                        <img *ngIf="data.offercover != '' " src="{{data.offercover}}" height="100%" width="100%" alt="">
                    </div>
                    <div class="card-footer card-footer-main">
                        <p  style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" tooltip="{{data.offertitle}}"><span  class="span1">{{data.offertitle}}</span></p>
                        <p style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"tooltip="{{data.offerdescription}}"><span class="span2">{{data.offerdescription}}</span></p>
                        <div class="text-center">
                            <button class="btn btn-danger"
                                routerLink="/viewOffers/{{data.offerid}}/{{data.storeid}}">{{allOffersJson.viewOffer}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" style="margin-top: 25px;margin-bottom: 20px;"
                *ngIf="allOffers?.length == 0">
                <img src="assets/offerji/nooffers_found_.png" alt="" style="object-fit: scale-down;width: 56%;">
                <!-- <h6>{{allOffersJson.noOffersFound}}</h6> -->
            </div>
        </div>
        <div style="padding-bottom: 20px;" class="col-lg-12 col-md-12">
            <div class="pagination-area text-center">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="Grid = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>