import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StoreDetails, UserDetails } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dashboard-profile',
    templateUrl: './dashboard-profile.component.html',
    styleUrls: ['./dashboard-profile.component.scss']
})
export class DashboardProfileComponent implements OnInit {

    profileForm: FormGroup;
    updateStoreForm: FormGroup;
    fname: string;
    gender: string;
    phonenumber: string;
    lname: string;
    useremail: string;
    accessuserid: any;
    nameCount1: any = '';
    userDetails: UserDetails = {};
    editUser: UserDetails = {};

    storeDetails: StoreDetails;
    storeid: string = "";
    editStore: StoreDetails = {};

    constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router) { }

    ngOnInit(): void {

        this.fname = localStorage.getItem("firstname")

        this.gender = localStorage.getItem("gender")

        this.lname = localStorage.getItem("lastname")

        this.phonenumber = localStorage.getItem("phonenumber")

        this.useremail = localStorage.getItem("useremail")

        this.accessuserid = localStorage.getItem("userid")
        // this.profileForm = this.formBuilder.group({
        //     useremail: ["",],
        //     firstname: ["",],
        //     lastname: ["",],
        // });

        this.api.getStoreDetails(this.accessuserid).subscribe((result: any) => {
            this.storeDetails = result.success[0];
            this.storeid = this.storeDetails.storeid;
            this.updatestoreform(this.storeDetails);
        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }

            if (error.error.code == 401) {
                // window.alert(error.error.message + " Please login!");
                localStorage.clear();

                this.router.navigate(['']);
            }
        })
        if (localStorage.getItem("isLoggedin") != "true") {
            this.router.navigate(['']);
        }

        this.fetchUserDetails();


    }

    fetchUserDetails() {
        this.api.getUserDetails(this.accessuserid).subscribe((result: any) => {
            this.userDetails = result.success[0];
            console.log(this.userDetails);

            this.profileform(this.userDetails);
        }, error => {
            if (error.error.code == 401) {
                // window.alert(error.error.message + " Please Login!");
                localStorage.clear();
                this.router.navigate(['']);
            }
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }
        })
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title3.jpg`,
            title: ``,
        }
    ]

    updateProfile(data: any) {
        this.editUser.useremail = data.useremail;
        console.log(data);

        this.editUser.firstname = data.firstname;
        this.editUser.lastname = data.lastname;
        this.editUser.phonenumber = data.phonenumber;
        this.editUser.accessuserid = this.accessuserid;
        this.api.updateUser(this.editUser).subscribe((result: any) => {
            if (result.code == 200) {
                // this.isFailed = false;
                // this.isSuccess = true;
                // this.toastr.success("User updated successfully")
                localStorage.setItem('firstname', this.editUser.firstname);
                localStorage.setItem('lastname', this.editUser.lastname);
                localStorage.setItem('phonenumber', this.editUser.phonenumber)
                // window.alert("User updated Successfully!");
                // window.location.reload();
                
                Swal.fire({
                    toast: false,
                    icon: 'success',
                    title: "User updated successfully",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 2000
                });
                this.fetchUserDetails();
            }
        }, error => {

            if (error.error.code == 400) {
                Swal.fire({
                    toast: false,
                    icon: 'error',
                    title: error.error.message,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 2000
                })
            }

            if (error.error.code == 401) {
                window.alert(error.error.message + " Please Login!");
                localStorage.clear();
                this.router.navigate[''];
            }
        })
    }

    profileform(userDetails: UserDetails) {
        this.profileForm = this.formBuilder.group({
            useremail: [userDetails.useremail, Validators.required],
            firstname: [userDetails.firstname, Validators.required],
            lastname: [userDetails.lastname, Validators.required],
            phonenumber: [userDetails.phonenumber]
        });
    }
    updatestoreform(storeDetails: StoreDetails) {
        this.updateStoreForm = this.formBuilder.group({
            storename: [storeDetails.storename, Validators.required],
            weburl: [storeDetails.weburl,],
            facebooklink: [storeDetails.facebooklink,],
            twitterlink: [storeDetails.twitterlink,],
            instagramlink: [storeDetails.instagramlink,],
            storedescription: [storeDetails.storedescription, Validators.required],
            storeaddress: [storeDetails.storeaddress,],
            storearea: [storeDetails.storearea],
            storecity: [storeDetails.storecity],
            storestate: [storeDetails.storestate],
            storezipcode: [storeDetails.storezipcode],
            storecountry: [storeDetails.storecountry],
        })
        console.log(storeDetails.storename);

    }

    updateStore(data: any) {
        console.log(true);

        console.log(data.storename);

        this.editStore.storename = data.storename;
        this.editStore.weburl = data.weburl;
        this.editStore.facebooklink = data.facebooklink;
        this.editStore.twitterlink = data.twitterlink;
        this.editStore.instagramlink = data.instagramlink;
        this.editStore.storedescription = data.storedescription;
        this.editStore.storeaddress = data.storeaddress;
        this.editStore.storearea = data.storearea;
        this.editStore.storecity = data.storecity;
        this.editStore.storestate = data.storestate;
        this.editStore.storezipcode = data.storezipcode;
        this.editStore.storecountry = data.storecountry;
        this.editStore.storeid = this.storeid;

        this.api.updateStore(this.editStore).subscribe((result: any) => {
            if (result.code == 200) {
                Swal.fire({
                    toast: false,
                    icon: 'success',
                    title: "Store updated successfully",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 2000
                });
                // window.alert("Store Updated Successfully!");
                // window.location.reload();

            }
        }, error => {

            if (error.error.code == 400) {
                // window.alert("Cannot update Store!");
                Swal.fire({
                    toast: false,
                    icon: 'error',
                    title: error.error.message,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 2000
                })
            }
        })
    }

    deleteStore() {
        this.api.deleteStore(this.storeid).subscribe((result: any) => {
            console.log(result);
            if (result.code == 200) {
                Swal.fire({
                    toast: false,
                    icon: 'success',
                    title: result.message,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 2000
                })
                window.location.reload();
            }
        }, error => {
            if (error.error.code == 400) {
                Swal.fire({
                    toast: false,
                    icon: 'error',
                    title: error.error.message,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width: '500px',
                    timer: 200
                })
            }
            if (error.error.message == 401) {
                window.alert(error.error.message + "Please Login Again!");
                localStorage.clear();
                this.router.navigate(['']);
            }
        })
    }
}