import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';
import dealsJson from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {

    dealsJson : any = dealsJson.dealsComponent
    categories: any;

    constructor(private api: ApiService) { }

    ngOnInit(): void {
        this.api.getcategories().subscribe((result: any) => {
            this.categories = result.success
        }, error => {
            if(error.error.code == 400){
                window.alert(error.error.message);
            }
        });
    }

    dealsSlides: OwlOptions = {
        nav: true,
        margin: 30,
        loop: true,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    }

}