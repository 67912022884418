
<div class="top-header-area">
    <div class="container-fluid {{containerClass}}">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <ul class="top-header-contact-info">
                    <li><a href="tel: +917416747480"><i class='bx bx-phone-call'></i>{{jsonData.phoneNumber}} </a></li>
                    <li><a href="mailto:offerji@offerji.com"><i class='bx bx-envelope'></i> {{jsonData.gmail}}</a></li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <li>
                            <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied">
                                <!-- <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/flag/us.jpg" class="shadow" alt="image">
                                    <span>Eng </span>
                                </button> -->
                                <!-- <div class="dropdown-menu">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/germany.jpg" class="shadow-sm" alt="flag">
                                        <span>Ger</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/france.jpg" class="shadow-sm" alt="flag">
                                        <span>Fre</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/spain.jpg" class="shadow-sm" alt="flag">
                                        <span>Spa</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/russia.jpg" class="shadow-sm" alt="flag">
                                        <span>Rus</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/italy.jpg" class="shadow-sm" alt="flag">
                                        <span>Ita</span>
                                    </a>
                                </div> -->
                            </div>
                        </li>
                        <li *ngIf="!isLoggedIn"><a routerLink="/profile-authentication"><i class='bx bx-log-in'></i>{{jsonData.signIn}}</a></li>
                        <li *ngIf="isLoggedIn"><a class="signout" (click)="signout()"><i class='bx bx-log-in'></i> {{jsonData.signOut}}</a></li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>