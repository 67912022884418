import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/common/about-us/about-us.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { ViewOffersComponent } from './components/common/view-offers/view-offers.component';
import { DashboardAddAgentComponent } from './components/my-dashboard/dashboard-add-agent/dashboard-add-agent.component';
import { DashboardAddCouponComponent } from './components/my-dashboard/dashboard-add-coupon/dashboard-add-coupon.component';
import { DashboardAddDealComponent } from './components/my-dashboard/dashboard-add-deal/dashboard-add-deal.component';
import { DashboardAgentsComponent } from './components/my-dashboard/dashboard-agents/dashboard-agents.component';
import { DashboardCouponsComponent } from './components/my-dashboard/dashboard-coupons/dashboard-coupons.component';
import { DashboardDealsComponent } from './components/my-dashboard/dashboard-deals/dashboard-deals.component';
import { DashboardProfileComponent } from './components/my-dashboard/dashboard-profile/dashboard-profile.component';
import { DashboardStoreComponent } from './components/my-dashboard/dashboard-store/dashboard-store.component';
import { DashboardVouchersComponent } from './components/my-dashboard/dashboard-vouchers/dashboard-vouchers.component';
import { MyDashboardComponent } from './components/my-dashboard/my-dashboard.component';
import { AllOffersComponent } from './components/pages/all-offers/all-offers.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { CategoriesPageComponent } from './components/pages/categories-page/categories-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CustomerServicePageComponent } from './components/pages/customer-service-page/customer-service-page.component';
import { DealsDetailsPageComponent } from './components/pages/deals-details-page/deals-details-page.component';
import { DealsPageComponent } from './components/pages/deals-page/deals-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { PreviewPageComponent } from './components/pages/preview-page/preview-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { StoreDetailsPageComponent } from './components/pages/store-details-page/store-details-page.component';

import { StoresPageComponent } from './components/pages/stores-page/stores-page.component';
import { TermsandconditionsComponent } from './components/pages/termsandconditions/termsandconditions.component';
import { ViewStoreComponent } from './components/pages/view-store/view-store.component';
import { SharedPageComponent } from './preview/share/shared-page/shared-page.component';

const routes: Routes = [
  
    {path: '', component: HomeDemoTwoComponent},
   
    {path: 'search-page', component: DealsPageComponent},
    {path: 'deals-details', component: DealsDetailsPageComponent},
    {path: 'all-stores', component: StoresPageComponent},
    {path: 'all-stores/:location/:category', component: StoresPageComponent},
    // {path: 'all-stores/:location', component: StoresPageComponent},
    // {path: 'all-stores/:category', component: StoresPageComponent},
    // {path: 'all-stores/:categoryname', component: StoresPageComponent},
    {path:"store-details/:storeid", component:StoreDetailsPageComponent},
    {path: 'categories', component: CategoriesPageComponent},
    {path: 'gallery', component: GalleryPageComponent},
    {path: 'how-it-works', component: HowItWorksPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'profile-authentication', component:ProfileAuthenticationPageComponent},
    {path: 'products-details', component: ProductsDetailsPageComponent},
    {path: 'privacypolicy', component: CustomerServicePageComponent},
    {path: 'coming-soon', component: ComingSoonPageComponent},
    {path: 'blog-grid', component: BlogGridPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'dashboard', component: MyDashboardComponent},
    {path: 'dashboard-profile', component: DashboardProfileComponent},
    {path: 'dashboard-store', component: DashboardStoreComponent},
    {path: 'dashboard-coupons', component: DashboardCouponsComponent},
    {path: 'dashboard-add-coupon', component: DashboardAddCouponComponent},
    {path: 'dashboard-deals', component: DashboardDealsComponent},
    {path: 'dashboard-add-deal', component: DashboardAddDealComponent},
    {path: 'dashboard-vouchers', component: DashboardVouchersComponent},
    {path: 'dashboard-agents', component: DashboardAgentsComponent},
    {path: 'dashboard-add-agent', component: DashboardAddAgentComponent},
    {path: 'aboutUs', component: AboutUsComponent},
    {path: 'viewOffers/:offerid/:storeid', component: ViewOffersComponent},
    {path: 'viewStore/:storeid',component: ViewStoreComponent},
    {path: 'AllOffers',component: AllOffersComponent},
    {path: 'AllOffers/:category',component: AllOffersComponent},
    // {path: 'preview/:offerid/:storeid', component: PreviewPageComponent},
    {path: 'blog-details/:story_id',component: BlogDetailsPageComponent},
    {path: 'preview/:offerid/:storeid', component: SharedPageComponent},
    {path: 'termsandconditions', component: TermsandconditionsComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }