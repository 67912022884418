<div class="banner-wrapper-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="banner-wrapper-content" >
                    <h1>{{homepageJson.title}}</h1>
                    <p>{{homepageJson.paragraph}}</p>
                    <form [formGroup]="searchStore">
                        <div class="row m-0 align-items-center">
                            <!-- <div class="col-lg-4 col-md-12 p-0">
                                <div class="form-group">
                                    <label><i class='bx bx-search-alt'></i></label>
                                    <input type="text" class="form-control" placeholder="What are you looking for?">
                                </div>
                            </div> -->
                            <div class="col-md-5 col-sm-6 p-0">
                                <div class="form-group category-select">
                                    <label><i class='bx bx-map'></i></label>
                                    <select formControlName="storelocation">
                                        <option >{{homepageJson.allLocations}}</option>
                                        <option *ngFor="let data of locations">{{data.storecity}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-6 p-0" >
                                <div class="form-group category-select">
                                    <label><i class='bx bx-category'></i></label>
                                    <select formControlName="storecategory">
                                        <option >{{homepageJson.allCategories}}</option>
                                        <option *ngFor="let data of categories">{{data.category_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-12 p-0">
                                <div class="submit-btn">
                                    <button type="submit" (click)="searchStoreNavigate(this.searchStore.value)">{{homepageJson.searchNow}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- <ul class="popular-search-list" >
                        <li>Popular:</li>
   
                        <li><a *ngFor="let data of selectedcat" routerLink="/search-page"> {{data.category_name}}</a></li>
                        
                    </ul> -->
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-wrapper-image" >
                    <img src="{{homepageJson.bannerImg}}" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>