<!-- <div class="header text-center" style="width: 100%;">
    <a href="{{storeDetails.weburl}}" *ngIf="storeDetails.weburl != ''"><h2><img src="{{storeDetails.storelogo}}" class="storelogo" alt="">
    {{storeDetails.storename}}</h2></a>
    <a href="" *ngIf="storeDetails.weburl == ''"><h2><img src="{{storeDetails.storelogo}}" class="storelogo" alt="">
        {{storeDetails.storename}}</h2></a>
</div>
<div class="products-details-area ptb-100" *ngIf="storeDetails">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <img src="{{offers.offercover}}" *ngIf="offers.offercover != ''" alt="image">
                    <img src="assets/img/coming-soon-bg.jpg" *ngIf="offers.offercover == ''" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h2>{{offers.offertitle}}</h2>


                    <p>{{offers.offerdescription}}</p>
                    <p>{{offerEnds}}</p>

                    <div class="products-share">
                        <ul class="social">
                            <li><a href="{{storeDetails.facebooklink}}" *ngIf="storeDetails.facebooklink != ''" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="{{storeDetails.twitterlink}}" *ngIf="storeDetails.twitterlink != ''" class="facebook" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="{{storeDetails.linkedinlink}}" *ngIf="storeDetails.linkedinlink != ''" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="{{storeDetails.instagramlink}}" *ngIf="storeDetails.instagramlink != ''" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="{{storeDetails.weburl}}" *ngIf="storeDetails.weburl != ''" target="_blank"><i class='bx bxl-chrome'></i></a></li>
                                <li><a href="/" *ngIf="storeDetails.weburl == ''" target="_blank"><i class='bx bxl-chrome'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="products-area pb-70">
    <div class="container">
        <div class="row text-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of offerItems">
                <div class="card">
                    <div class="card-header" style="height: 210px;">
                        <img src="{{item.offer_item_image}}" style="height: 190px;width: auto;object-fit: scale-down;" alt="">
                    </div>
                    <div class="card-body">
                        <div class="item-info">
                            <h6>{{item.offer_item_name}}</h6>
                            <div style="display: flex; " *ngIf="offers.offertype == 'Campaign'">
                                <h6 [innerHTML]="item.currencyicon"></h6>
                                <h6 style="text-decoration:line-through ;" class="location">{{item.offer_item_mrp}} </h6>
                            </div>
                            <div style="display: flex; " *ngIf="offers.offertype == 'Campaign'">
                                <h4 [innerHTML]="item.currencyicon" ></h4>
                                <h4>{{item.offer_item_selling_price}}</h4>
                            </div>
        
                            <div style="display: flex; " *ngIf="offers.offertype == 'Catalogue'">
                                <p [innerHTML]="item.currencyicon" ></p>
                                <p>{{item.offer_item_mrp}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
        
    </div>
</div>



<div class="footer store-footer card">
    <div class="container store-container">
        <div class="col-md-4 storecolumn-1">
            <div class="footer-column">
                <a href=""><img src="{{storeDetails.storelogo}}" alt=""></a>
                
                <h4>{{storeDetails.storename}}</h4>
                <p>{{storeDetails.storedescription}}<br>
                    contact: <a href="tel: {{storeDetails.phonenumber}}">{{storeDetails.phonenumber}}</a><br>
                </p>
                
            </div>
        </div>
        <div class="col-md-4 storecolumn-2">
            <h3>Let's Connect</h3>
            <ul class="social-links">
                <li *ngIf="storeDetails.facebooklink != ''"><a href="{{storeDetails.facebooklink}}"  target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li *ngIf="storeDetails.twitterlink != ''"><a href="{{storeDetails.twitterlink}}"  target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li *ngIf="storeDetails.linkedinlink != ''"><a href="{{storeDetails.linkedinlink}}"  target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li *ngIf="storeDetails.instagramlink != ''"><a href="{{storeDetails.instagramlink}}"  target="_blank"><i class="bx bxl-instagram"></i></a></li>
                <li *ngIf="storeDetails.weburl !='' "><a href="{{storeDetails.weburl}}"  target="_blank"><i class="bx bxl-chrome"></i></a></li>
            </ul>
        </div>
        <div class="col-md-4 storecolumn-3">
            <u style="color: red;"><h2>Contact Info</h2></u>
            <p>{{storeDetails.storearea}},<br>
            {{storeDetails.storeaddress}},<br>
            {{storeDetails.storecity}},<br>
            {{storeDetails.storestate}},<br>
            {{storeDetails.storecountry}},<br>
            {{storeDetails.storezipcode}}.</p>
        </div>
    </div>
</div> -->
<div class="main">
    <section>
        <div class="header">
            <nav class="nav-bar">

                <div class="storename-div text-center">
                    <a>
                        <img src="{{storeDetails.storelogo}}" class="storelogo" alt="">
                    </a>
                    <span>{{storeDetails.storename}}</span>
                </div>
            </nav>
        </div>
    </section>

    <section>
        <div class="content">
            <div class="container storedetails" *ngIf="offers">
                <div class="row offer-div">
                    <div class="col-sm-5">

                        <img src="{{offers.offercover}}" class="offerPicture" alt="">
                    </div>
                    <div class="col-sm-7" style="padding-top: 50px;">
                        <div class="store-offer-details">
                            <div class="row">
                                <div class="col-sm-12 mainbannerText">
                                    <span class="ellips">
                                        <h3 tooltip="{{offers.offertitle}}">{{offers.offertitle}}
                                        </h3>
                                    </span>
                                    <p class="ellips" tooltip="{{offers.offerdescription}}">
                                        {{offers.offerdescription}}</p>
                                    <p style="color: #666666;">{{offers.offertype}}</p>
                                    <p style="display: flex;" *ngIf="offers.offertype == 'Campaign'">Start-Date: <span
                                            style="color: #666666;">{{offers.offerstartdate}}</span></p>
                                    <p style="display: flex;" *ngIf="offers.offertype == 'Campaign'">End-Date : <span
                                            style="color: #666666;">{{offers.offerenddate}}</span></p>


                                    <p>Offer Status: <span style="color: #666666;">{{offers.offerstatus}}</span></p>
                                    <div class="products-share">
                                        <ul class="social">
                                            <li><a href="{{storeDetails.facebooklink}}"
                                                    *ngIf="storeDetails.facebooklink != ''" class="facebook"
                                                    target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                            <li><a href="{{storeDetails.twitterlink}}"
                                                    *ngIf="storeDetails.twitterlink != ''" class="facebook"
                                                    target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                            <!-- <li><a href="#" class="whatsapp" target="_blank"><i class='bx bxl-whatsapp'></i></a></li> -->
                                            <li><a href="{{storeDetails.linkedinlink}}"
                                                    *ngIf="storeDetails.linkedinlink != ''" class="linkedin"
                                                    target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                            <li><a href="{{storeDetails.instagramlink}}"
                                                    *ngIf="storeDetails.instagramlink != ''" class="instagram"
                                                    target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                            <!-- <li><a href="#"  class="facebook" target="_blank"><i class='bx bxs-home'></i></a></li> -->
                                            <li tooltip="Store Link"><a href="{{storeDetails.weburl}}"
                                                    *ngIf="storeDetails.weburl != ''" target="_blank"><i
                                                        class='bx bxl-chrome'></i></a></li>
                                            <!-- <li><a href="/" *ngIf="storeDetails.weburl == ''" target="_blank"><i
                                                        class='bx bxl-chrome'></i></a></li> -->
                                        </ul>
                                    </div>
                                    <!-- {{offers | json}} -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container offers">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let offeritem of offerItems"
                    style="padding-bottom: 10px;">
                    <div class="card" style="margin: 10px;">
                        <div class="card-header" style="padding: 0;">
                            <div class="offerItem text-center">
                                <img src="{{offeritem.offer_item_image}}" alt="">
                                <!-- <a href="javascript:void(0)" class="bookmark-save" [class.active]="heart"><i
                                        class='bx bx-heart'></i></a> -->
                            </div>

                        </div>
                        <div class="card-body">
                            <div class="item-info text-center">
                                <!-- <h6>{{offeritem.offer_item_name}}</h6> -->
                            </div>

                            <div class="deals-details-content">

                                <div class="rating d-flex align-items-center">
                                    <!-- <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="bx bxs-star checked"></span>
                                    <span class="rating-count">(45)</span> -->
                                </div>

                                <h3 style=" white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;" tooltip="{{offeritem.offer_item_name}}">
                                    <a>{{offeritem.offer_item_name}}</a>
                                </h3>

                                <span style=" white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;" tooltip="{{offeritem.offer_item_description}}">
                                    <p>{{offeritem.offer_item_description}}</p>
                                </span>



                                <div class="box-footer">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="price" style="display: flex;"
                                            *ngIf="offers.offertype == 'Campaign'">

                                            <span class="old-price" [innerHTML]="offeritem.currencyicon"></span>
                                            <span class="old-price"
                                                *ngIf="offeritem.offer_item_selling_price">{{offeritem.offer_item_mrp}}</span>
                                            &nbsp;&nbsp;
                                            <span [innerHTML]="offeritem.currencyicon"></span>
                                            <span class="new-price">{{offeritem.offer_item_selling_price}}</span>
                                        </div>

                                        <!-- <div *ngIf="offers.offertype == 'Catalogue'" class="price" style="display: flex;display: none;">

                                            <span class="old-price" [innerHTML]="offeritem.currencyicon"></span>
                                            <span class="old-price"
                                                *ngIf="offeritem.offer_item_selling_price">{{offeritem.offer_item_mrp}}</span>
                                            &nbsp;&nbsp;
                                            <span [innerHTML]="offeritem.currencyicon"></span>
                                            <span class="new-price">{{offeritem.offer_item_selling_price}}</span>
                                        </div> -->

                                        <!-- <a routerLink="" class="details-btn">View Details</a> -->
                                        <button (click)="buyNowLink(offeritem.itembuynowlink)" type="button"
                                            class="default-btn" *ngIf="offeritem.itembuynowlink != '' ">BUY NOW</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section>
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12" style="display: flex;">
                                <img src="{{storeDetails.storelogo}}" class="storelogo" alt="">
                                <h4 style="margin-left: 20px;color: #024d94;">{{storeDetails.storename}}</h4>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                {{storeDetails.storedescription}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <p style="color: #024d94;">contact: <a href="tel:{{storeDetails.phonenumber}}"
                                        style="text-decoration: underline;color: #024d94;">{{storeDetails.phonenumber}}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <h6 style="color: #024d94;">Contact Info</h6>
                        <p style="color: #024d94;"><br>
                            {{storeDetails.storeaddress}},<br>
                            {{storeDetails.storecity}},<br>
                            {{storeDetails.storestate}},<br>
                            {{storeDetails.storecountry}},<br>
                            {{storeDetails.storezipcode}}.</p>
                    </div>
                </div>
            </div>
        </div>
    </section> -->

</div>
<section>
    <div class="firstFooter" style="background: var(--yellowColor);padding-top: 10px;padding-bottom: 10px;">
        <div class="newFooter">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-2 text-center">
                        <a>
                            <img src="{{storeDetails.storelogo}}" class="storelogo" alt="">
                        </a>
                    </div>
                    <div class="col-md-10 storename-div text-center">
                        <h2 class="storename">{{storeDetails.storename}}</h2>
                        <p style="color: #fff;" class="storedescription">{{storeDetails.storedescription}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container text-center">
        <h6>{{storeDetails.storedescription}}</h6>
    </div> -->

</section>
<div class="container" style="padding: 20px;">
    <div class="row justify-content-between">
        <div class="col-6 contact">
            <h4 style="color:#fb3939;">Address</h4>

            <p>{{storeDetails.storecity}},</p>
            <p>{{storeDetails.storestate}},</p>
            <p>{{storeDetails.storecountry}},</p>
            <p>{{storeDetails.storezipcode}}.</p>
        </div>
        <div class="col-6 contact" style="text-align: right;">
            <h4 style="color: #fb3939;">Contact Info </h4>
            <!-- <p> {{storeDetails.storeaddress}},</p>
            <p>{{storeDetails.storename}}</p>
            <p> {{storeDetails.storedescription}}</p> -->
            <p style="color: #024d94;"><a href="tel:{{storeDetails.phonenumber}}"
                    style="text-decoration: underline;color: #024d94;">{{storeDetails.phonenumber}}</a>
            </p>
        </div>
    </div>
    <div class="storename-div1 text-center">
        <a>
            <img src="assets/offerji/offerji_logo.png" style="width: 110px;
            height: 64px;
            border-radius: 100%;" class="storelogo" alt="">
        </a>
        <span style="color: #000;font-size: 16px;">Developed by <a href="#"> Saadruso </a></span>
    </div>
</div>