<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<section class="deals-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="deals-details-image-slides">
                    <owl-carousel-o [options]="dealsDetailsImageSlides">
                        <ng-template carouselSlide>
                            <div class="article-image">
                                <img src="assets/img/deals/deals1.jpg" alt="image">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="article-image">
                                <img src="assets/img/deals/deals2.jpg" alt="image">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="article-image">
                                <img src="assets/img/deals/deals3.jpg" alt="image">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="deals-details-content">
                    <h3>The Start Hotel, Casino</h3>
                    <div class="rating d-flex align-items-center">
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="rating-count">(45)</span>
                    </div>
                    <ul class="d-flex align-items-center">
                        <li class="phone-number">
                            <a href="tel:+2122791456"><i class="bx bx-phone-call"></i> (+212) 279-1456</a>
                        </li>
                        <li class="location">
                            <i class="bx bx-map"></i>
                            <span>Location</span>
                            New York, USA
                        </li>
                    </ul>
                </div>
                <div class="deals-details-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <p><strong>1.</strong> Complimentary ground shipping within 1 to 7 business days<br>
                        <strong>2.</strong> In-store collection available within 1 to 7 business days<br>
                        <strong>3.</strong> Next-day and Express delivery options also available<br>
                        <strong>4.</strong> Purchases are delivered in an orange box tied with a Bolduc ribbon, with the exception<br>
                        <strong>5.</strong> See the delivery FAQs for details on shipping methods, costs and delivery times
                    </p>
                    <h3>About This Products</h3>
                    <p><strong>offerji</strong> accepts the following payment methods:</p>
                    <ul>
                        <li>Credit Card: Visa, MasterCard, Discover, American Express, JCB, Visa Electron. The total will be charged to your card when the order is shipped.</li>
                        <li><strong>offerji</strong> features a Fast Checkout option, allowing you to securely save your credit card details so that you don't have to re-enter them for future purchases.</li>
                        <li>PayPal: Shop easily online without having to enter your credit card details on the website.Your account will be charged once the order is completed. To register for a PayPal account, visit the website <a href="#" target="_blank">paypal.com.</a></li>
                    </ul>
                    <blockquote class="wp-block-quote">
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <cite>Tom Cruise</cite>
                    </blockquote>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <div class="deals-details-review-comments">
                    <div class="user-review">
                        <img src="assets/img/user5.jpg" alt="image">
                        <div class="review-rating">
                            <div class="review-stars">
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                            </div>
                            <span class="d-inline-block">James Anderson</span>
                        </div>
                        <span class="d-block sub-comment">Excellent</span>
                        <p>Very well built theme, couldn't be happier with it. Can't wait for future updates to see what else they add in.</p>
                    </div>
                    <div class="user-review">
                        <img src="assets/img/user2.jpg" alt="image">
                        <div class="review-rating">
                            <div class="review-stars">
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                            <span class="d-inline-block">Sarah Taylor</span>
                        </div>
                        <span class="d-block sub-comment">Video Quality!</span>
                        <p>Was really easy to implement and they quickly answer my additional questions!</p>
                    </div>
                    <div class="user-review">
                        <img src="assets/img/user3.jpg" alt="image">
                        <div class="review-rating">
                            <div class="review-stars">
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                            </div>
                            <span class="d-inline-block">David Warner</span>
                        </div>
                        <span class="d-block sub-comment">Perfect Coding!</span>
                        <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                    </div>
                    <div class="user-review">
                        <img src="assets/img/user4.jpg" alt="image">
                        <div class="review-rating">
                            <div class="review-stars">
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                            <span class="d-inline-block">King Kong</span>
                        </div>
                        <span class="d-block sub-comment">Perfect Video!</span>
                        <p>Stunning design, very dedicated crew who welcome new ideas suggested by customers, nice support.</p>
                    </div>
                </div>
                <div class="deals-details-review-form">
                    <h3>Add a review</h3>
                    <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="rating">
                                    <input type="radio" id="star5" name="rating" value="5"><label for="star5"></label>
                                    <input type="radio" id="star4" name="rating" value="4"><label for="star4"></label>
                                    <input type="radio" id="star3" name="rating" value="3"><label for="star3"></label>
                                    <input type="radio" id="star2" name="rating" value="2"><label for="star2"></label>
                                    <input type="radio" id="star1" name="rating" value="1"><label for="star1"></label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name *">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email *">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea placeholder="Your review" class="form-control" cols="30" rows="6" spellcheck="false"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" id="test1">
                                    <label for="test1">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_deals_sidebar">
                        <div class="price">
                            <span class="new-price">$299.00</span>
                            <span class="old-price">$330.00</span>
                        </div>
                        <div class="buy-now-btn">
                            <a routerLink="/deals-details" class="default-btn">Buy Now</a>
                            <span><i class='bx bx-cart bx-flashing'></i> 247+ bought</span>
                        </div>
                        <ul class="deals-value">
                            <li>Value <span>$330.00</span></li>
                            <li>Discount <span>50%</span></li>
                            <li>You Save <span>$165.00</span></li>
                        </ul>
                        <div class="offer-timer">
                            <p>Time Left - Limited Offer!</p>
                            <div id="timer" class="flex-wrap d-flex justify-content-center">
                                <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                                <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                                <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                                <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget_maps">
                        <h3 class="widget-title">In Short</h3>
                        <div class="content">
                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
                        </div>
                    </div>
                    <div class="widget widget_author">
                        <h3 class="widget-title">About Author</h3>
                        <div class="content">
                            <img src="assets/img/user1.jpg" alt="image">
                            <h4>Chris Orwig</h4>
                            <span>Photographer, Author, Writer</span>
                            <a routerLink="/contact" class="default-btn">Contact With Me</a>
                        </div>
                    </div>
                    <div class="widget widget_socials_link">
                        <h3 class="widget-title">Stay Connected</h3>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i> Facebook</a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i> Linkedin</a></li>
                        </ul>
                    </div>
                
                </aside>
            </div>
        </div>
    </div>
</section>