<div class="page-title-area"
  style="background-image: url({{aboutUsJson.bgImage}});background-color:var(--yellowColor);">
  <div class="container">
    <h1>{{aboutUsJson.title}}</h1>
    <p>{{aboutUsJson.paragraph}}</p>
  </div>
</div>
<!-- <h2 class="text-center" style="padding-top: 30px;padding-bottom:25px"> About Us</h2> -->
<div class=" about container" style="padding-top: 45px;text-align: justify;">
  <h6>{{aboutUsJson.bgBelowParagraph}}</h6>
</div>


<section class="benefit">
  <div class="container my-5">
    <div class="row">
      <div class="col-md-6 p-4 p-sm-5 order-2 order-sm-1">

        <h1 class="h2 " style="font-weight: 600; padding-top: 80px;width: max-content;">{{aboutUsJson.paraTitle1}} </h1>
        <b>
          <p class="text-secondary">{{aboutUsJson.customerParaGraph}}</p>
        </b>
        <p class="text-secondary" style="line-height: 2;">{{aboutUsJson.customerParaGraph2}}</p>

        <p style="font-weight:700 ;"><a style="color: var(--redColor);">{{aboutUsJson.benefits}}</a>
          {{aboutUsJson.benefitsText}}</p>

      </div>
      <div class="col-md-6 p-0 text-center order-1 order-sm-2">
        <img style=" padding-top: 80px;" src="{{aboutUsJson.secondParaImage}}" class="w-100 " alt="">
      </div>

      <div class="col-md-6 p-0 text-center">
        <img src="{{aboutUsJson.firstParaImg}}" class="w-100" alt="">
      </div>
      <div class="col-md-6 p-4 p-sm-5" style="padding: 2rem !important;">

        <h1 class="h2" style="font-weight: 600;">{{aboutUsJson.firstParaTitle}} </h1>
        <b>
          <p class="text-secondary">{{aboutUsJson.firstParaTitle1}}</p>
        </b>
        <p class="text-secondary" style="line-height: 2;">
          {{aboutUsJson.firstParaTitle2}}</p>

        <p style="font-weight:700 ;"><a style="color: var(--redColor);">{{aboutUsJson.firstParaBenefits}}</a>
          {{aboutUsJson.firstParaBenefitsText}}</p>


      </div>
    </div>
  </div>
</section>


<div style="margin-top: 50px;margin-bottom:50px" class="why-choose-area  ptb-100">
  <div class="container-fluid">
    <div class="section-title" *ngFor="let Title of aboutUsJson.sectionTitle;">
      <h2>{{Title.title}}</h2>
      <p>{{Title.paragraph}}</p>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Content of aboutUsJson.singleWhyChooseBox;">
        <div class="single-why-choose-box">
          <i class='{{Content.icon}}'></i>
          <h3>{{Content.title}}</h3>
          <span>{{Content.subTitle}}</span>
        </div>
      </div>
    </div>

    <div class="why-choose-content" *ngFor="let Content of aboutUsJson.whyChooseContent;">
      <h3>{{Content.title}}</h3>
      <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
      <p class="text-center text-light" style="padding-top: 20px;">{{Content.promoteText}}</p>
    </div>
  </div>
</div>

<!-- <app-why-choose-us></app-why-choose-us> -->