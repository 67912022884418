<div class="container">
    <div class="partner-inner-area ptb-70">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title text-start" *ngFor="let Title of sectionTitle;">
                    <h2>{{Title.title}}</h2>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="partner-list">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-6" *ngFor="let Item of partnerItem;">
                            <div class="partner-item">
                                <img [src]="Item.img" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>