import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import jsonData from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class TopHeaderComponent implements OnInit {
    jsonData: any = jsonData.topHeader

    location: any;
    containerClass: any;
    isLoggedIn: boolean = false;

    constructor(private api: ApiService,
        private router: Router,
        location: Location
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.location = this.router.url;
                    if (this.location == '/index-3' || this.location == '/locations' || this.location == '/blog-details' || this.location == '/checkout') {
                        this.containerClass = 'container';
                    } else {
                        this.containerClass = 'container-fluid';
                    }
                }
            });
    }

    ngOnInit(): void {
        if (localStorage.getItem("isLoggedin")) {
            this.isLoggedIn = true;
            this.api.loginSubject.next({ loggedIn: true })
        }
        this.api.loginSubject.subscribe((res) => {
            this.isLoggedIn = res.loggedIn
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    signout() {
        this.api.loginSubject.next({ loggedIn: false })
        // localStorage.clear
        // localStorage.removeItem("firstname");
        // localStorage.removeItem("isLoggedin");
        // localStorage.removeItem("userid");
        // localStorage.removeItem("accesskey");
        // localStorage.removeItem("useremail");
        // localStorage.removeItem("storeid");
        // localStorage.removeItem("lastname");
        // localStorage.removeItem("gender");
        // localStorage.removeItem("phonenumber");
        localStorage.clear();
        this.router.navigate(['']);
        // window.location.reload();
    }

}