import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from "ngx-accordion";
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { JoinNowComponent } from './components/common/join-now/join-now.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { BlogComponent } from './components/common/blog/blog.component';

import { FeaturedDealsComponent } from './components/common/featured-deals/featured-deals.component';
import { DealsComponent } from './components/common/deals/deals.component';
import { PopularStoreComponent } from './components/common/popular-store/popular-store.component';
import { PopularDealsComponent } from './components/common/popular-deals/popular-deals.component';
import { PartnerStyleOneComponent } from './components/common/partner-style-one/partner-style-one.component';
import { FeaturedDiscountDealsComponent } from './components/common/featured-discount-deals/featured-discount-deals.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { TodaysHotDealsComponent } from './components/common/todays-hot-deals/todays-hot-deals.component';
import { HttpClientModule } from '@angular/common/http';
import { TopHeaderComponent } from './components/common/top-header/top-header.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { BestDealsComponent } from './components/common/best-deals/best-deals.component';
import { DealsWithTabsComponent } from './components/common/deals-with-tabs/deals-with-tabs.component';

import { PartnerStyleTwoComponent } from './components/common/partner-style-two/partner-style-two.component';
import { CategoriesWithDealsComponent } from './components/common/categories-with-deals/categories-with-deals.component';
import { PropertyTypeDealsComponent } from './components/common/property-type-deals/property-type-deals.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { CustomerServicePageComponent } from './components/pages/customer-service-page/customer-service-page.component';
import { CategoriesPageComponent } from './components/pages/categories-page/categories-page.component';
import { StoresPageComponent } from './components/pages/stores-page/stores-page.component';

import { DealsPageComponent } from './components/pages/deals-page/deals-page.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { DealsDetailsPageComponent } from './components/pages/deals-details-page/deals-details-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { RelatedProductsComponent } from './components/pages/products-details-page/related-products/related-products.component';
import { MyDashboardComponent } from './components/my-dashboard/my-dashboard.component';
import { DashboardProfileComponent } from './components/my-dashboard/dashboard-profile/dashboard-profile.component';
import { DashboardStoreComponent } from './components/my-dashboard/dashboard-store/dashboard-store.component';
import { DashboardCouponsComponent } from './components/my-dashboard/dashboard-coupons/dashboard-coupons.component';
import { DashboardAddCouponComponent } from './components/my-dashboard/dashboard-add-coupon/dashboard-add-coupon.component';
import { DashboardDealsComponent } from './components/my-dashboard/dashboard-deals/dashboard-deals.component';
import { DashboardAddDealComponent } from './components/my-dashboard/dashboard-add-deal/dashboard-add-deal.component';
import { DashboardVouchersComponent } from './components/my-dashboard/dashboard-vouchers/dashboard-vouchers.component';
import { DashboardAgentsComponent } from './components/my-dashboard/dashboard-agents/dashboard-agents.component';
import { DashboardAddAgentComponent } from './components/my-dashboard/dashboard-add-agent/dashboard-add-agent.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { AboutUsComponent } from './components/common/about-us/about-us.component';
import { ViewOffersComponent } from './components/common/view-offers/view-offers.component';
import { ViewStoreComponent } from './components/pages/view-store/view-store.component';
import { AllOffersComponent } from './components/pages/all-offers/all-offers.component';
import { SafePipe } from './safe.pipe';
import { pipe } from 'rxjs';
import { StoreDetailsPageComponent } from './components/pages/store-details-page/store-details-page.component';
import { PreviewPageComponent } from './components/pages/preview-page/preview-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { SharedPageComponent } from './preview/share/shared-page/shared-page.component';
import { TermsandconditionsComponent } from './components/pages/termsandconditions/termsandconditions.component';

@NgModule({
  declarations: [
    AppComponent,

    HomeDemoTwoComponent,

    JoinNowComponent,
    WhyChooseUsComponent,
    BlogComponent,

    FeaturedDealsComponent,
    DealsComponent,
    PopularStoreComponent,
    PopularDealsComponent,
    PartnerStyleOneComponent,
    FeaturedDiscountDealsComponent,
    FooterComponent,
    TodaysHotDealsComponent,

    TopHeaderComponent,
    NotFoundComponent,
    ContactPageComponent,
    NavbarStyleTwoComponent,

    HometwoBannerComponent,
    BestDealsComponent,
    DealsWithTabsComponent,

    PartnerStyleTwoComponent,
    CategoriesWithDealsComponent,
    PropertyTypeDealsComponent,
    FaqPageComponent,
    CustomerServicePageComponent,
    CategoriesPageComponent,
    StoresPageComponent,

    DealsPageComponent,
    HowItWorksPageComponent,
    ComingSoonPageComponent,
    BlogGridPageComponent,
    GalleryPageComponent,
    DealsDetailsPageComponent,
    ProductsDetailsPageComponent,
    RelatedProductsComponent,
    MyDashboardComponent,
    DashboardProfileComponent,
    DashboardStoreComponent,
    DashboardCouponsComponent,
    DashboardAddCouponComponent,
    DashboardDealsComponent,
    DashboardAddDealComponent,
    DashboardVouchersComponent,
    DashboardAgentsComponent,
    DashboardAddAgentComponent,
    ProfileAuthenticationPageComponent,
    AboutUsComponent,
    ViewOffersComponent,
    ViewStoreComponent,
    AllOffersComponent,
    SafePipe,
    StoreDetailsPageComponent,
    PreviewPageComponent,
    BlogDetailsPageComponent,
    SharedPageComponent,
    TermsandconditionsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    ClipboardModule,
    TooltipModule,
    FormsModule,
    NgxScrollTopModule,
    TabsModule,
    StickyNavModule,
    AccordionModule,
    NgxPaginationModule,
    LightboxModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
