<!-- Start Join Now Area -->
<div class="join-now-area ptb-100">
    <div class="container">
        <div class="join-now-content" >
            <h2>{{joinNowJson.title}}</h2>
            <p>{{joinNowJson.paragraph}}</p>
            <a routerLink="/{{joinNowJson.buttonLink}}" class="default-btn">{{joinNowJson.buttonText}}</a>
        </div>
    </div>
</div>
<!-- End Join Now Area -->