import { Component, OnInit } from '@angular/core';
import customerServiceJson from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-customer-service-page',
    templateUrl: './customer-service-page.component.html',
    styleUrls: ['./customer-service-page.component.scss']
})
export class CustomerServicePageComponent implements OnInit {

    customerServiceJson : any = customerServiceJson.customerPageServiceComponent

    constructor() { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title5.jpg`,
            title: ``,
        }
    ]

}