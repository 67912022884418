import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-blog-grid-page',
    templateUrl: './blog-grid-page.component.html',
    styleUrls: ['./blog-grid-page.component.scss']
})
export class BlogGridPageComponent implements OnInit {

    blogGrid: number = 1;
    stories: any;
    storyid: string = "";

    constructor(private api: ApiService) { }

    ngOnInit(): void {
        this.api.getBlogs(this.storyid).subscribe((result: any) => {
            console.log(result);
            this.singleBlogPost = result.success;
        })
    }

    pageTitle = [
        {
            bgImage: `assets/offerji-template/blog_main_1920_500px.png`,
            title: ``,
        }
    ]
    singleBlogPost = [
        {
            image: `assets/offerji-template/Blogs_booost_your_brand_.jpg`,
            tag: `Travel`,
            tagLink: `blog-grid`,
            title: `The top 5 destinations for travel in July 2020: New edition`,
            paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
            detailsText: `Read More`,
            detailsLink: `blog-details`
        },
        {
            image: `assets/offerji-template/Blogs_advantages_of_offers_.jpg`,
            tag: `Business`,
            tagLink: `blog-grid`,
            title: `A 10-step guide to decarbonising your travel business`,
            paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
            detailsText: `Read More`,
            detailsLink: `blog-details`
        },
        {
            image: `assets/offerji-template/Blogs_discoutns_2.jpg`,
            tag: `Europe`,
            tagLink: `blog-grid`,
            title: `10 things you’ll experience on an Intrepid Retreat in Europe`,
            paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
            detailsText: `Read More`,
            detailsLink: `blog-details`
        },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Travel`,
        //     tagLink: `blog-grid`,
        //     title: `Be Together: the joys and challenges of active adventures`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Business`,
        //     tagLink: `blog-grid`,
        //     title: `Sharing culture and art with a local Aboriginal artist`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Europe`,
        //     tagLink: `blog-grid`,
        //     title: `Introducing our reconciliation action plan artwork`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Travel`,
        //     tagLink: `blog-grid`,
        //     title: `Be Together: the joys and challenges of active adventures`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Business`,
        //     tagLink: `blog-grid`,
        //     title: `Sharing culture and art with a local Aboriginal artist`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Europe`,
        //     tagLink: `blog-grid`,
        //     title: `Introducing our reconciliation action plan artwork`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Travel`,
        //     tagLink: `blog-grid`,
        //     title: `The top 5 destinations for travel in July 2020: New edition`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Business`,
        //     tagLink: `blog-grid`,
        //     title: `A 10-step guide to decarbonising your travel business`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // },
        // {
        //     image: `assets/offerji/blog-cs.jpg`,
        //     tag: `Europe`,
        //     tagLink: `blog-grid`,
        //     title: `10 things you’ll experience on an Intrepid Retreat in Europe`,
        //     paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.`,
        //     detailsText: `Read More`,
        //     detailsLink: `blog-details`
        // }
    ]

}