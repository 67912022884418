<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="deals-area ptb-10">
    <div class="container">
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-sm-6 col-md-6"
                *ngFor="let data of items | paginate: { itemsPerPage: 12, currentPage: dealsGrid }">
                <div class="single-deals-box1">
                    <div class="deals-image">
                        <a class="d-block">
                            <img [src]="data.offer_item_image" alt="image" style="height: 252px;
                                    object-fit: cover;
                                    width: 100%;">

                        </a>

                        <!-- <a href="javascript:void(0)" class="bookmark-save"><i class='bx bx-heart'></i></a> -->
                        <!-- <div class="discount" *ngIf="Content.discount">{{Content.discount}}</div> -->
                        <!-- <a routerLink="" class="show-coupon-code" target="_blank" ngxClipboard [cbContent]="[Content.couponCode]" [container]="container" tooltip="Click to Copy" placement="right" show-delay="100" *ngIf="Content.couponCode">Show Coupon Code</a> -->
                    </div>
                    <div class="deals-content">
                        <div>

                        </div>
                        <div class="d-flex align-items-center ">
                            <!-- <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span class="fa fa-star checked"></span>
                            <span>(35)</span> -->
                            <div style="float: right;" class="rating justify-content-around">
                                <!-- <i class="{{Icon.icon}}" *ngFor="let Icon of Content.ratingStar"></i> -->
                                <!-- <span class="count">{{data.item_status}}</span> -->
                            </div>
                            <!-- <div class="status">
                                        <span *ngIf="Content.trending"><i class='bx bx-trending-up'></i> {{Content.trending}}</span>
                                    </div> -->
                        </div>
                        <h3 style=" white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;"><a routerLink=""
                                tooltip="{{data.offer_item_name}}">{{data.offer_item_name}}</a></h3>
                        <span style=" white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;" class="location" tooltip="{{data.offer_item_description}}">
                            {{data.offer_item_description}}</span>
                    </div>
                    <div class="box-footer" *ngIf="offers.offertype == 'Campaign'">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price" style="display: flex;">

                                <span class="old-price" [innerHTML]="data.currencyicon"></span>
                                <span class="old-price"
                                    *ngIf="data.offer_item_selling_price">{{data.offer_item_mrp}}</span>
                                &nbsp;&nbsp;
                                <span [innerHTML]="data.currencyicon"></span>
                                <span class="new-price">{{data.offer_item_selling_price}}</span>
                            </div>
                            <!-- <a routerLink="" class="details-btn">View Details</a> -->
                            <button (click)="buyNowLink(data.itembuynowlink)" type="button" class="default-btn"
                                *ngIf="data.itembuynowlink != '' ">BUY NOW</button>
                        </div>
                    </div>


                    <div class="box-footer" style="display: flex;display: none;" *ngIf="offers.offertype == 'Catalogue'" >
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="price" style="display: flex;">

                                <span class="old-price" [innerHTML]="data.currencyicon"></span>
                                <span class="old-price"
                                    *ngIf="data.offer_item_selling_price">{{data.offer_item_mrp}}</span>
                                &nbsp;&nbsp;
                                <span [innerHTML]="data.currencyicon"></span>
                                <span class="new-price">{{data.offer_item_selling_price}}</span>
                            </div>
                            <!-- <a routerLink="" class="details-btn">View Details</a> -->
                            <button (click)="buyNowLink(data.itembuynowlink)" type="button" class="default-btn"
                                *ngIf="data.itembuynowlink != '' ">BUY NOW</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <!-- <div class="col-xl-3 col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Category</h3>
                        <ul>
                            <li><a routerLink="/"><i class='bx bx-happy-beaming'></i> Beauty</a></li>
                            <li><a routerLink="/"><i class='bx bxs-t-shirt'></i> Clothing</a></li>
                            <li><a routerLink="/"><i class='bx bx-laptop'></i> Computers</a></li>
                            <li><a routerLink="/"><i class='bx bx-devices'></i> Electronics</a></li>
                            <li><a routerLink="/"><i class='bx bx-chair'></i> Furniture</a></li>
                            <li><a routerLink="/"><i class='bx bx-first-aid'></i> Health</a></li>
                            <li><a routerLink="/"><i class='bx bx-camera'></i> Photography</a></li>
                            <li><a routerLink="/"><i class='bx bx-bowling-ball'></i> Baby and Kids</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_stores">
                        <h3 class="widget-title">Stores</h3>
                        <ul>
                            <li>
                                <input type="checkbox" id="excavator" name="excavator" value="Excavator">
                                <label for="excavator">Excavator <span class="count">(0)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="theBird" name="theBird" value="The Bird">
                                <label for="theBird">The Bird <span class="count">(1)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="tripleOne" name="tripleOne" value="Triple One">
                                <label for="tripleOne">Triple One <span class="count">(2)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="hiTech" name="hiTech" value="Hi-Tech">
                                <label for="hiTech">Hi-Tech <span class="count">(3)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="desire" name="desire" value="Desire">
                                <label for="desire">Desire <span class="count">(4)</span></label>
                            </li>
                            <li>
                                <input type="checkbox" id="lorem" name="lorem" value="Lorem">
                                <label for="lorem">Lorem <span class="count">(5)</span></label>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_looking_for">
                        <h3 class="widget-title">I'm looking for</h3>
                        <ul>
                            <li>
                                <input type="radio" id="all" name="lookingFor" value="lookingFor" checked>
                                <label for="all">All</label>
                            </li>
                            <li>
                                <input type="radio" id="dealsOnly" name="lookingFor" value="lookingFor">
                                <label for="dealsOnly">Deals Only</label>
                            </li>
                            <li>
                                <input type="radio" id="couponsOnly" name="lookingFor" value="lookingFor">
                                <label for="couponsOnly">Coupons Only</label>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_custom_ads">
                        <a href="#" class="d-block" target="_blank">
                            <img src="assets/img/custom-ads1.jpg" alt="image">
                        </a>
                    </div>
                </aside>
            </div> -->

    </div>
</div>