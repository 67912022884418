import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-blog-details-page',
    templateUrl: './blog-details-page.component.html',
    styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {
    storyid: string = "";

    constructor(private api: ApiService, private rout: ActivatedRoute) { }

    ngOnInit(): void {
        this.storyid = this.rout.snapshot.paramMap.get("story_id");
        this.api.getBlogs(this.storyid).subscribe((result: any) => {
            console.log(result);
            
        })
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title2.jpg`,
            title: `The top 5 destinations for travel in July 2020: New edition`,
        }
    ]
}