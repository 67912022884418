import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import popularStoreJson from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-popular-store',
    templateUrl: './popular-store.component.html',
    styleUrls: ['./popular-store.component.scss']
})
export class PopularStoreComponent implements OnInit {

    popularStoreJson: any = popularStoreJson.popularStoreComponent
    category: string = "";
    location: string = "";
   
    constructor(private api: ApiService, private route: ActivatedRoute) { }
    Allstores: any[] = []
    aStores: any
    bStores: any
    cStores: any
    dStores: any
    eStores: any
    fStores: any
    gStores: any
    hStores: any
    iStores: any
    ngOnInit(): void {

        
    this.route.queryParams.subscribe(params => {
        console.log(params);
      });
      console.log(this.route.snapshot.queryParamMap.get('id'));


        this.api.getStores(this.category, this.location).subscribe((result: any) => {

            for (let e of result.success) {
                this.Allstores = this.Allstores.concat(e.Stores).slice(0, 12);

            }
            console.log(this.Allstores);
            


            // this.aStores = result.success[0].Stores.slice(0, 3);

            // this.bStores = result.success[1].Stores.slice(0, 3);

            // this.cStores = result.success[2].Stores.slice(0, 3);

            // this.dStores = result.success[3].Stores.slice(0, 3);

            // this.eStores = result.success[4].Stores.slice(0, 3);

            // this.fStores = result.success[5].Stores.slice(0, 3);

            // this.gStores = result.success[6].Stores.slice(0, 3);

            // this.hStores = result.success[7].Stores.slice(0, 3);

            // this.iStores = result.success[8].Stores.slice(0, 3);
        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }
        });
    }


    getName(id: string) {
        return 'name-for-' + id;
    }



}