<!-- <div class="page-title-area" *ngFor="let Content of categoryPageJson.pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class=".page-title-area1">

    <div class="wrapper">
        <owl-carousel-o [options]="customOptions">

            <ng-container  *ngFor="let Content of categoryPageJson.pageTitle;">
                <ng-template class="Content" carouselSlide [id]="Content.id">
                    <img [src]="Content.bgImage" [alt]="Content.id" [title]="Content.id">
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>

<div class="categories-area pt-100 pb-70">
    <div class="container">
        <div class="filter-block">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <!-- <ul>
                        <li><a routerLink="/categories" class="active">All</a></li>
                        <li><a routerLink="/categories">A</a></li>
                        <li><a routerLink="/categories">B</a></li>
                        <li><a routerLink="/categories">C</a></li>
                        <li><a routerLink="/categories">D</a></li>
                        <li><a routerLink="/categories">Z</a></li>
                    </ul> -->
                </div>
                <div class="col-lg-4 col-md-5">
                    <form>
                        <input type="text" class="form-control" placeholder="{{categoryPageJson.searchCategory}}">
                        <button type="submit"><i class='bx bx-search-alt'></i></button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6" *ngFor="let category of categories;">
                <!-- <div class="single-deals-box">
                    <div class="deals-image">
                        <a routerLink="/AllOffers/{{category.category_name}}" class="d-block">
                            <img style="object-fit:contain; padding-top:15px;    width: 100%;    aspect-ratio: 16/13;
                            text-align: center;" src="{{category.category_image}}" alt="image">
                        </a>
                    </div>
                    <div class="deals-content">
                    </div>
                    <div class="box-footer" style="padding-top: 20px;">
                        <h4 class="text-center"><a
                                routerLink="/AllOffers/{{category.category_name}}">{{category.category_name}}</a></h4>
                    </div>
                </div> -->
                <div class="hoverCard" style="padding-bottom: 50px;">
                    <div class="content">
                        <a routerLink="/AllOffers/{{category.category_name}}">
                            <div class="content-overlay"></div>
                            <img class="content-image" src="{{category.category_image}}" alt="">
                            <div class="content-details fadeIn-bottom">
                                <h4 style="color: #fff;"> <a style="color: #fff;" class="content-title"
                                        routerLink="/AllOffers/{{category.category_name}}">{{category.category_name}}</a>
                                </h4>
                            </div>
                        </a>
                    </div>
                </div> 

            </div>
        </div>
    </div>
</div>