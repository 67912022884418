<div class="container">
    <div class="row">
        <div class="col-lg-9 col-md-12">
            <div class="section-title text-start">
                <h2>Today´s Hottest Discount Coupons</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div class="single-deals-coupon-box" *ngFor="let Content of singleDealsCouponBox;">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="deals-coupon-image" style="background-image: url({{Content.image}});">
                            <img [src]="Content.image" class="main-image" alt="image">
                            <div class="content">
                                <img [src]="Content.logo" alt="image">
                                <a routerLink="/{{Content.logoLink}}" class="link-btn">{{Content.logoText}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="deals-coupon-content">
                            <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                            <p>{{Content.paragraph}}</p>
                            <div class="show-coupon-code">
                                <span>Coupon Code:</span>
                                <a routerLink="/{{Content.detailsLink}}" class="code-btn" target="_blank" ngxClipboard [cbContent]="[Content.couponCode]" [container]="container" tooltip="Click to Copy" placement="right" show-delay="100">Show Coupon Code <i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-12">
            <aside class="widget-area">
                <div class="widget widget_categories">
                    <h3 class="widget-title">Popular Categories</h3>
                    <ul>
                        <li><a routerLink="/search-page"><i class='bx bx-glasses-alt'></i> Accessories</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-female'></i> Entertainment</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-bowling-ball'></i> Baby and Kids</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-happy-beaming'></i> Beauty</a></li>
                        <li><a routerLink="/search-page"><i class='bx bxs-t-shirt'></i> Clothing</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-laptop'></i> Computers</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-devices'></i> Electronics</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-chair'></i> Furniture</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-first-aid'></i> Health</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-home-smile'></i> Home and Garden</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-coin'></i> Jewelry</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-store-alt'></i> Office Supplies</a></li>
                        <li><a routerLink="/search-page"><i class='bx bx-camera'></i> Photography</a></li>
                    </ul>
                </div>
                <div class="widget widget_custom_ads">
                    <a href="#" class="d-block" target="_blank">
                        <img src="assets/img/custom-ads1.jpg" alt="image">
                    </a>
                </div>
                <div class="widget widget_custom_ads">
                    <a href="#" class="d-block" target="_blank">
                        <img src="assets/img/custom-ads2.jpg" alt="image">
                    </a>
                </div>
            </aside>
        </div>
    </div>
</div>