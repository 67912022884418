<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="dashboard-tabs-list">
            <ul>
                <li><a routerLink="/dashboard">Dashboard</a></li>
                <li><a routerLink="/dashboard-profile">Profile</a></li>
                <li><a routerLink="/dashboard-store" class="active">Store</a></li>
                <!-- <li><a routerLink="/dashboard-coupons">Coupons</a></li> -->
                <!-- <li><a routerLink="/dashboard-deals">Deals</a></li> -->
                <!-- <li><a routerLink="/dashboard-vouchers">Vouchers</a></li> -->
                <!-- <li><a routerLink="/dashboard-agents">Agents</a></li> -->
            </ul>
        </div>

        <div class="row" *ngIf="!hasStore">
            <div class="col-lg-12 col-md-12">
                <h3>Create a store From the app</h3>
            </div>
        </div>

        <div class="row" *ngIf="hasStore">
            <div class="col-lg-4 col-md-12">
                <div class="dashboard-profile-box">
                    <div class="image">
                        <!-- <img src="assets/img/user1.jpg" alt="image"> -->
                        <img src="{{storeDetails.storelogo}}" alt="">
                        <span>{{storeDetails.storename}}</span>
                    </div>
                    <p>View <a routerLink="/dashboard">All offers</a></p>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isSuccess">
                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" (click)="close()">&times;</span>
                    </button>
                </div>
                <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isFailed">
                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" (click)="close()">&times;</span>
                    </button>
                </div> -->
                <div class="dashboard-stats">
                    <form [formGroup]="updateStoreForm" *ngIf="updateStoreForm">
                        <div class="row">
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Logo <span>*</span></label>
                                    <input type="file">
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Name <span>*</span></label>
                                    <input type="text" formControlName="storename" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Link</label>
                                    <input type="text" formControlName="weburl" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Facebook Link</label>
                                    <input type="text" formControlName="facebooklink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Twitter Link</label>
                                    <input type="text" formControlName="twitterlink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Instagram Link</label>
                                    <input type="text" formControlName="instagramlink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Store Description</label>
                                    <textarea cols="30" rows="8" formControlName="storedescription"
                                        class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Address</label>
                                    <input type="text" formControlName="storeaddress" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Area</label>
                                    <input type="text" formControlName="storearea" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store City</label>
                                    <input type="text" formControlName="storecity" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store State</label>
                                    <input type="text" formControlName="storestate" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Country</label>
                                    <input type="text" formControlName="storecountry" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Pincode</label>
                                    <input type="text" formControlName="storezipcode" class="form-control">
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Store Locations</label>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
                                    <iframe src="https://maps.google.com/maps?q='+{{storeDetails.latitude}}+','+{{storeDetails.longitude}}+'&hl=es;z=14&amp;output=embed"></iframe>
                                    <iframe [src]="locationlink | safe"></iframe>
                                    <iframe width="300" height="170" frameborder="0" scrolling="no" marginheight="0"
                                        marginwidth="0" [src]="locationlink | safe">
                                    </iframe>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" (click)="updateStore(this.updateStoreForm.value)"
                                    class="default-btn">Update Store</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>