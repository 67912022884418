import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreDetails } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-shared-page',
  templateUrl: './shared-page.component.html',
  styleUrls: ['./shared-page.component.scss']
})
export class SharedPageComponent implements OnInit {
  offerid: string;
  storeid: string;
  storeDetails: StoreDetails = {};
  offers: any;
  offerItems: any;
  heartColor: boolean = false;

  constructor(private rout: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {
    this.offerid = this.rout.snapshot.paramMap.get("offerid");
    this.storeid = this.rout.snapshot.paramMap.get("storeid");

    this.api.storeDetailsWithid(this.storeid).subscribe((result: any) => {
      if (result.code == 200) {
        this.storeDetails = result.success[0];
        if (!(this.storeDetails.facebooklink.includes("https://") || this.storeDetails.facebooklink.includes("http://") || this.storeDetails.facebooklink.includes("HTTPS://") || this.storeDetails.facebooklink.includes("HTTP://"))) {
          if (this.storeDetails.facebooklink != "") {
            this.storeDetails.facebooklink = "//" + this.storeDetails.facebooklink;
          }
        }
        if (!(this.storeDetails.instagramlink.includes("https://") || this.storeDetails.instagramlink.includes("http://") || this.storeDetails.instagramlink.includes("HTTPS://") || this.storeDetails.instagramlink.includes("HTTP://"))) {
          if (this.storeDetails.instagramlink != "") {
            this.storeDetails.instagramlink = "//" + this.storeDetails.instagramlink;
          }
        }
        if (!(this.storeDetails.twitterlink.includes("https://") || this.storeDetails.twitterlink.includes("http://") || this.storeDetails.twitterlink.includes("HTTPS://") || this.storeDetails.twitterlink.includes("HTTP://"))) {
          if (this.storeDetails.twitterlink != "") {
            this.storeDetails.twitterlink = "//" + this.storeDetails.twitterlink;
          }
        }
        if (!(this.storeDetails.weburl.includes("https://") || this.storeDetails.weburl.includes("http://") || this.storeDetails.weburl.includes("HTTPS://") || this.storeDetails.weburl.includes("HTTP://"))) {
          if (this.storeDetails.weburl != "") {
            this.storeDetails.weburl = "//" + this.storeDetails.weburl;
          }
        }
        if (!(this.storeDetails.linkedinlink.includes("https://") || this.storeDetails.linkedinlink.includes("http://") || this.storeDetails.linkedinlink.includes("HTTPS://") || this.storeDetails.linkedinlink.includes("HTTP://"))) {
          if (this.storeDetails.linkedinlink != "") {
            this.storeDetails.linkedinlink = "//" + this.storeDetails.linkedinlink;
          }
        }
      }
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    })

    this.api.OffersPreview(this.storeid, this.offerid).subscribe((result: any) => {
      if (result.code == 200) {
        this.offers = result.success[0];
      }
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    })

    this.api.getOfferItems(this.offerid).subscribe((result: any) => {
      console.log(result);
      if (result.code == 200) {
        this.offerItems = result.success;
      }
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    })
  }


  buyNowLink(value: any) {
    window.open(value);
    console.log(value);
    
}

}
