<div class="container">
    <div class="section-title text-start">
        <h2>{{dealsJson.title}}</h2>
        <p>{{dealsJson.paragraph}}</p>
        <a class="viewOffers-btn" routerLink="/categories">{{dealsJson.viewAllCategories}}</a>
    </div>


    <div class="deals-slides">
        <owl-carousel-o [options]="dealsSlides">
            <ng-template carouselSlide *ngFor="let category of categories;">

                

<div class="container">
 <div class="hoverCard">
    <div class="content">
      <a routerLink="/AllOffers/{{category.category_name}}" >
        <div class="content-overlay"></div>
        <img class="content-image" src="{{category.category_image}}" alt="">
        <div class="content-details fadeIn-bottom">
         <h4 style="color: #fff;"> <a style="color: #fff;" class="content-title" routerLink="/AllOffers/{{category.category_name}}">{{category.category_name}}</a></h4>
        </div>
      </a>
    </div>
</div>
  </div>
  
  

                <!-- <div class="single-deals-box2">
                    <div class="content-overlay"></div>
                    <div class="deals-image">
                        <a routerLink="/AllOffers/{{category.category_name}}" class="d-block">
                            <img style="object-fit: fill;width: 100%; aspect-ratio: 16/9;
                            object-fit:cover;
                            max-width:100%;" src="{{category.category_image}}" alt="image">
                        </a>

                    </div>
                    <div class="deals-content">



                    </div>
                    <div class="box-footer">
                        <h4 class="text-center"><a
                                routerLink="/AllOffers/{{category.category_name}}">{{category.category_name}}</a></h4>
                    
                    </div>
                </div> -->
            </ng-template>
        </owl-carousel-o>
    </div>
</div>


