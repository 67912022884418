import { Component, OnInit } from '@angular/core';
import aboutUsJson from 'src/assets/config.json/offerji.json'
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  aboutUsJson : any = aboutUsJson.aboutUsComponent;

  constructor() { }

  ngOnInit(): void {
  }
 

}
