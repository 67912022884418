<div class="page-title-area" *ngFor="let Content of viewOffersJson.pageTitle;"
    style="background-image: url({{Content.bgImage}});background-color: var(--redColor);background-size: contain;">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="products-details-area ptb-100" *ngIf="storeDetails">
    <div class="container" *ngIf="offers">
        <!-- <div class="back" style="margin-bottom: 30px;">
            <button class="btn btn-primary" (click)="goback()">Back</button>
        </div> -->
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="products-details-image">
                    <img style="width: 100%;height: auto;" src="{{offers.offercover}}" *ngIf="offers.offercover != ''"
                        alt="image">
                    <img style="width: 100%;height: auto;" src="{{viewOffersJson.img}}" *ngIf="offers.offercover == ''"
                        alt="image">
                </div>
            </div>

            <div class="col-sm-6 ">
                <div class="products-details-desc">
                    <!-- <ul class="social" style="list-style: none;">
                        <li>
                            <p tooltip="{{offers.offertitle}}" style=" white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; font-size: 30px;color: #000;font-weight: 600;">{{offers.offertitle}}</p>
                        </li>
                        <li>
                            <p style=" white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;" tooltip="{{offers.offerdescription}}">{{offers.offerdescription}}
                            </p>
                            <span  style="display: flex;" *ngIf="offers.offertype == 'Campaign'" > {{viewOffersJson.startDate}}<p> {{offers.offerstartdate}}</p>
                            </span>
                            <span s style="display: flex;" *ngIf="offers.offertype == 'Campaign'"> {{viewOffersJson.endDate}} <p>{{offers.offerenddate}}</p>
                            </span>

                            <span  style="display: flex;display: none;" *ngIf="offers.offertype == 'Catalogue'" > {{viewOffersJson.startDate}}<p> {{offers.offerstartdate}}</p>
                            </span>
                            <span  style="display: flex;display: none;" *ngIf="offers.offertype == 'Catalogue'"> {{viewOffersJson.endDate}} <p>{{offers.offerenddate}}</p>
                            </span>

                            <p>{{offerEnds}}</p>
                        </li>
                    </ul> -->


                    <span class="ellips">
                        <h3 tooltip="{{offers.offertitle}}" style="color: #000;font-size: 25px;">{{offers.offertitle}}
                        </h3>
                    </span>
                    <p class="ellips" tooltip="{{offers.offerdescription}}">
                        {{offers.offerdescription}}</p>
                    <p style="color: #666666;">{{offers.offertype}}</p>
                    <p style="display: flex;" *ngIf="offers.offertype == 'Campaign'">Start-Date: <span
                            style="color: #666666;">{{offers.offerstartdate}}</span></p>
                    <p style="display: flex;" *ngIf="offers.offertype == 'Campaign'">End-Date : <span
                            style="color: #666666;">{{offers.offerenddate}}</span></p>


                    <p>Offer Status: <span style="color: #666666;">{{offers.offerstatus}}</span></p>



                    <div class="products-share">
                        <ul class="social">
                            <!-- <li><a href="{{storeDetails.facebooklink}}" *ngIf="storeDetails.facebooklink != ''"
                                    class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="{{storeDetails.twitterlink}}" *ngIf="storeDetails.twitterlink != ''"
                                    class="facebook" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="whatsapp" target="_blank"><i class='bx bxl-whatsapp'></i></a></li>
                            <li><a href="{{storeDetails.linkedinlink}}" *ngIf="storeDetails.linkedinlink != ''"
                                    class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="{{storeDetails.instagramlink}}" *ngIf="storeDetails.instagramlink != ''"
                                    class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                            <!-- <li><a href="#" class="facebook" target="_blank"><i class='bx bxs-home'></i></a></li> -->
                            <li tooltip="Store Website"><a href="{{storeDetails.weburl}}" *ngIf="storeDetails.weburl != ''" target="_blank"><i
                                        class='bx bxl-chrome'></i></a></li>
                            <li tooltip="Store Website"><a href="/" *ngIf="storeDetails.weburl == ''" target="_blank"><i
                                        class='bx bxl-chrome'></i></a></li>
                            <li tooltip="Slideshow" *ngIf="items?.length != 0 && isLoggedin && carouselaccess"><a (click)="largeScreen()"
                                    class="play_offer" clicked id="play" data-toggle="modal"
                                    data-target="#exampleModal"><i class='bx bx-play'></i></a></li>
                            <li tooltip="View Offer">
                                <a href="{{offers.offershortlink}}" target="_blank" class="play_offer">
                                    <i class="bx bx-globe" aria-hidden="true"></i>
                                </a>
                            </li>
                            <!-- <li>
                                <a style="cursor: pointer;" class="play_offer"><i class="fa fa-sticky-note"
                                        aria-hidden="true" (click)="copyMessage()"></i></a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-deals-page></app-deals-page>

<div class="products-area pb-70" *ngIf="offers">
    <div class="container">
        <div class="row text-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of items">
                <!-- <div class="card">
                    <div class="card-header" style="height: 210px;">
                        <img src="{{item.offer_item_image}}" style="height: 190px;width: auto;object-fit: scale-down;"
                            alt="">
                    </div>
                    <div class="card-body">
                        <div class="item-info">
                            <h6>{{item.offer_item_name}}</h6>
                            <div style="display: flex; " *ngIf="offers.offertype == 'Campaign'">
                                <h6>MRP</h6>&nbsp; <h6 [innerHTML]="item.currencyicon"></h6>
                                <h5 style="text-decoration:line-through solid red;" class="location">
                                    {{item.offer_item_mrp}} </h5>

                            </div>
                            <div style="display: flex; " *ngIf="offers.offertype == 'Campaign'">
                                <h6 style="padding-top: 7px;"></h6>&nbsp; <h4
                                    [innerHTML]="item.currencyicon"></h4>
                                <h3 style="color: red;">{{item.offer_item_selling_price}}</h3>
                            </div>

                            <div style="display: flex; " *ngIf="offers.offertype == 'Catalogue'">
                                <p [innerHTML]="item.currencyicon"></p>
                                <p>{{item.offer_item_mrp}}</p>

                            </div>
                            <div style="float: right;">
                                <p *ngIf="item.itembuynowlink != ''"><a
                                        style="text-decoration: underline;font-weight:600"
                                        href="https://{{item.itembuynowlink}}" target="_blank">Buy Now</a></p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>




        </div>
    </div>

</div>

<div class="modal" *ngIf="items" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-fullscreen" role="document">
        <div *ngIf="offers" class="modal-content">
            <div style="background-color:beige;" class="modal-header text-center ">
                <h2 style="text-transform: uppercase;" class="modal-title " id="exampleModalLabel"><img
                        *ngIf="storeDetails" src="{{storeDetails.storelogo}}" style="border-radius: 100%;" height="70px"
                        width="70px"><span *ngIf="storeDetails">&nbsp;{{storeDetails.storename}}</span>
                </h2>
                <button type="button" class="btn-close" data-dismiss="modal" (click)="closefullscreen()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div style="overflow: hidden;background-color: beige;" class="modal-body">
                <div class='container-fluid center'>

                    <!-- <div id="carouselExampleSlidesOnly" class="carousel slide offer-carosel" data-ride="carousel">
                        <div class="carousel-inner">

                            <div class="carousel-item" [class.active]="isFirst"
                            
                                *ngFor="let offer of items;first as isFirst">
                                
                                <div class="row">
                                    <div class="col-lg-7 card-column">
                                        <div class="card img-card">
                                            <img class="d-block w-100 carousel-image" src="{{offer.offer_item_image}}"
                                                alt="{{offer.offer_itemid}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-5 data-column">
                                        <div class="card" style="margin-top: 30px;background: transparent;">
                                            <div class="card-header Offer-header">
                                                {{offer.offer_item_name}}
                                            </div>
                                            <div class="card-body">
                                                <h3>Price: <span id="price">{{offer.offer_item_selling_price}}</span>
                                                </h3>
                                                <img src="http://chart.apis.google.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl={{storeDetails.weburl}}"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <owl-carousel-o [options]="homeSlides" *ngIf="items">
                        <ng-template carouselSlide *ngFor="let item of items;">
                            <div class="main-banner-box" style="margin-top: 1%;height: 600px;">
                                <div class="row container-fluid" style="height: 500px;">
                                    <div class="col-md-7">
                                        <img src="{{item.offer_item_image}}" style="height: 470px;width: 470px;margin-top: 7%;margin-left: 20%;transform: rotate(-15deg);
                                        box-shadow: -5px 5px 17px 5px rgb(1 1 1 / 50%);" alt="">
                                    </div>
                                    <div class="col-md-5"
                                        style="box-shadow: -5px 5px 17px 5px rgb(1 1 1 / 50%);border-bottom-left-radius: 20px">
                                        <div class="row" style="padding-top: 25px;">
                                            <div class="col-lg-12 text-center">
                                                <h1>{{item.offer_item_name}}</h1>
                                            </div>
                                        </div>
                                        <hr>
                                        <div style="padding-top: 50px;" class="mrp">
                                            <div class="col-lg-6">
                                                <span style="display: flex;">
                                                    <p style="font-weight: 500;color: black;font-size: 22px"><b>MRP:
                                                        </b></p>
                                                    <p
                                                        style="text-decoration:line-through 2px red;color: black;font-weight: 800;font-size: 22px;">
                                                        {{item.offer_item_mrp}}</p>
                                                </span>
                                            </div>

                                            <div class="col-lg-6">
                                                <span style="display: flex;">
                                                    <p style="font-weight: 500;color: black;font-size: 24px;"><b>Our
                                                            Price: </b></p>
                                                    <p style="font-weight: 800;color: black;font-size: 24px;">
                                                        {{item.offer_item_selling_price}}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <!-- <img [src]="item.offer_item_image" style="height: 500px;width: 500px;" alt="image">
                                <div class="content">
                                    <h3>{{item.offer_item_name}}</h3>
                                    <p>{{item.offer_item_description}}</p>
                                </div> -->
                            <!-- <div class="row caurosel">
                                    <div class="col-sm-7">
                                        <div class="card caurosel-image">
                                            <img [src]="item.offer_item_image"
                                                style="box-shadow: -5px 5px 17px 5px rgb(1 1 1 / 50%);"
                                                class="caurosel-img" alt="Offer image not found">
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="card text-card">
                                            <div class="card-body">
                                                <div class="Offer-header">
                                                    <h2><a>Item Name:</a> {{item.offer_item_name}}</h2>
                                                </div>
                                                <h3>Price: <span id="price">{{item.offer_item_selling_price}}</span>
                                                </h3>
                                                <div class="storeimageqr">
                                                    <img class="storeqr"
                                                        src="http://chart.apis.google.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl={{storeDetails.weburl}}"
                                                        alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
 
                                </div> -->

                        </ng-template>
                    </owl-carousel-o>

                </div>
            </div>

        </div>
    </div>
</div>