import { Component, OnInit } from '@angular/core';
import howItWorksJson from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: ['./how-it-works-page.component.scss']
})
export class HowItWorksPageComponent implements OnInit {

    howItWorksJson: any = howItWorksJson.howItWorksComponent
    isLoggedIn: boolean = false;
    constructor() { }

    ngOnInit(): void {
        if (localStorage.getItem("isLoggedin") == "true") {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    }

    navigate(value) {
        value
    }



}