import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OfferItems, StoreDetails, } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import viewOffersJson from 'src/assets/config.json/offerji.json'

@Component({
  selector: 'app-view-offers',
  templateUrl: './view-offers.component.html',
  styleUrls: ['./view-offers.component.scss']
})
export class ViewOffersComponent implements OnInit {


  viewOffersJson : any = viewOffersJson.ViewOffersComponent
  isLoggedin: boolean = false;
  carouselaccess: boolean = false;
  offerType: any;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // $('#exampleModal').on
    // event.preventDefault();
    // window.location.reload();
  }
  offerid: any;
  storeid: any;
  offers: any;
  offerEnds: any;
  items: any;
  elem: any;
  storeDetails: StoreDetails = {};
  constructor(private route: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {

    if (localStorage.getItem("isLoggedin") == null && localStorage.getItem("isLoggedin") != "true") {
      this.isLoggedin = false;
    } else {
      this.isLoggedin = true;
    }

    console.log(this.isLoggedin);



    this.elem = document.documentElement
    this.offerid = this.route.snapshot.paramMap.get('offerid');
    this.storeid = this.route.snapshot.paramMap.get('storeid');

    this.api.Offers(this.storeid, this.offerid).subscribe((result: any) => {
      this.offers = result.success[0]
      console.log(this.offers.offertype);
      this.offerType = this.offers.offertype
      
      // this.offerEnds = result.success.EndsIn
      // this.storeDetails = result.success.Stores;

      // this.items = result.success.Items;


    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    });

    this.api.storeDetailsWithid(this.storeid).subscribe((result: any) => {
      // console.log(result);
      this.storeDetails = result.success[0];
      if (!(this.storeDetails.facebooklink.includes("https://") || this.storeDetails.facebooklink.includes("http://") || this.storeDetails.facebooklink.includes("HTTPS://") || this.storeDetails.facebooklink.includes("HTTP://"))) {
        if (this.storeDetails.facebooklink != "") {
          this.storeDetails.facebooklink = "//" + this.storeDetails.facebooklink;
        }
      }
      if (!(this.storeDetails.instagramlink.includes("https://") || this.storeDetails.instagramlink.includes("http://") || this.storeDetails.instagramlink.includes("HTTPS://") || this.storeDetails.instagramlink.includes("HTTP://"))) {
        if (this.storeDetails.instagramlink != "") {
          this.storeDetails.instagramlink = "//" + this.storeDetails.instagramlink;
        }
      }
      if (!(this.storeDetails.twitterlink.includes("https://") || this.storeDetails.twitterlink.includes("http://") || this.storeDetails.twitterlink.includes("HTTPS://") || this.storeDetails.twitterlink.includes("HTTP://"))) {
        if (this.storeDetails.twitterlink != "") {
          this.storeDetails.twitterlink = "//" + this.storeDetails.twitterlink;
        }
      }
      if (!(this.storeDetails.weburl.includes("https://") || this.storeDetails.weburl.includes("http://") || this.storeDetails.weburl.includes("HTTPS://") || this.storeDetails.weburl.includes("HTTP://"))) {
        if (this.storeDetails.weburl != "") {
          this.storeDetails.weburl = "//" + this.storeDetails.weburl;
        }
      }
      if (!(this.storeDetails.linkedinlink.includes("https://") || this.storeDetails.linkedinlink.includes("http://") || this.storeDetails.linkedinlink.includes("HTTPS://") || this.storeDetails.linkedinlink.includes("HTTP://"))) {
        if (this.storeDetails.linkedinlink != "") {
          this.storeDetails.linkedinlink = "//" + this.storeDetails.linkedinlink;
        }
      }
      if (localStorage.getItem("userid") == this.storeDetails.accessuserid) {
        this.carouselaccess = true;
      } else {
        this.carouselaccess = false;
      }
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    });


    this.api.getOfferItems(this.offerid).subscribe((result: any) => {
      this.items = result.success;
      console.log(this.items);
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    });

  }

  copyMessage(val: string){
    console.log(val);
    val = this.offers.offershortlink
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  largeScreen() {

    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }

  }

  closefullscreen() {
    window.location.reload();
  }

  homeSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: false,
    autoplay: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoHeight: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        autoHeight: true
      },
      992: {
        autoHeight: true
      }
    }
  }

 
}


