import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import allOffersJson from 'src/assets/config.json/offerji.json'

@Component({
  selector: 'app-all-offers',
  templateUrl: './all-offers.component.html',
  styleUrls: ['./all-offers.component.scss']
})
export class AllOffersComponent implements OnInit {

  allOffersJson: any = allOffersJson.AllOffersComponent
  allOffers: any
  Grid: number = 1;
  category: string = "";
  alloffers: string = "";
  storeid: string = "";
  offertype: string = "";
  constructor(private api: ApiService, private route: ActivatedRoute, private rout: Router) { }

  ngOnInit(): void {
    if ((this.route.snapshot.paramMap.get('category')) == undefined || (this.route.snapshot.paramMap.get('category') == "") || (this.route.snapshot.paramMap.get('category') == null)) {
      this.category = "";

    } else {
      this.category = this.route.snapshot.paramMap.get('category');
      console.log("category");
    }
    console.log(this.category);

    this.api.getDeals(this.category, this.storeid, this.offertype).subscribe((result: any) => {
      this.allOffers = result.success
      console.log(this.allOffers);

    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
      if (error.error.code == 401) {
        localStorage.clear();
        window.alert(error.error.message);
        this.rout.navigate(['']);
      }
    })
  }

}
