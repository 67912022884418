



<div class="container" style="padding-bottom: 100px;">
    <div class="section-title text-start" >
        <h2>{{bestDealsJson.title}}</h2>
        <p>{{bestDealsJson.paragraph}}</p>
   
        <a class="viewOffers-btn" routerLink="/AllOffers">{{bestDealsJson.viewOffers}}</a>
    </div>

    <div class="deals-slides">
        <owl-carousel-o [options]="dealsSlides">
            <ng-template carouselSlide *ngFor="let Deal of Deals;">
                <div class="single-deals-box1">
                    <div class="deals-image">
                        <a routerLink="/viewOffers/{{Deal.offerid}}/{{Deal.storeid}}" class="d-block">
                            <img style="height: 200px;object-fit: cover;" src="{{Deal.offercover}}" alt="image">
                        </a>
                        
                        
                    </div>
                    <div style="height: 100px;" class="deals-content" >
                    
                        <h3 style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><a routerLink="/viewOffers/{{Deal.offerid}}/{{Deal.storeid}}">{{Deal.offertitle}}</a></h3>
                        <span class="location">{{bestDealsJson.expireOn}} {{Deal.offerenddate}}</span>
                    </div>
                    <div class="box-footer">
                        <div class="d-flex align-items-center justify-content-between">
                        
                            <a routerLink="/viewOffers/{{Deal.offerid}}/{{Deal.storeid}}" class="details-btn">{{bestDealsJson.viewDetails}}</a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>