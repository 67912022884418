<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="dashboard-tabs-list">
            <ul>
                <li><a routerLink="/dashboard">Dashboard</a></li>
                <li><a routerLink="/dashboard-profile" class="active">Profile</a></li>
                <!-- <li><a routerLink="/dashboard-store">Store</a></li> -->
                <!-- <li><a routerLink="/dashboard-coupons">Coupons</a></li> -->
                <!-- <li><a routerLink="/dashboard-deals">Deals</a></li> -->
                <!-- <li><a routerLink="/dashboard-vouchers">Vouchers</a></li> -->
                <!-- <li><a routerLink="/dashboard-agents">Agents</a></li> -->
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12" *ngIf="storeDetails">
                <div class="dashboard-profile-box">
                    <div class="image">
                        <img *ngIf="storeDetails.storename != ''" src="{{storeDetails.storelogo}}" alt="image">
                        <!-- <img *ngIf="gender == 'FEMALE'" src="assets/img/female_avatar.jpg" alt="image">
                        <img *ngIf="gender == 'MALE'" src="assets/img/male_avatar.png" alt="image"> -->
                        <!-- <span>{{fname}} {{lname}}</span> -->
                        <span style=" white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;" tooltip="{{storeDetails.storename}}">{{storeDetails.storename}}</span>
                    </div>
                    <!-- <p>Gender: {{gender}}</p> -->
                    <p>Phone Number: {{userDetails.phonenumber}}</p>
                </div>
            </div>


            <div class="col-lg-8 col-md-12">
                <!-- Alert -->
                <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isSuccess">
                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true" (click)="close()">&times;</span>
                    </button>
                </div>
                <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isFailed">
                    <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div> -->
                <div class="dashboard-stats">
                    <form [formGroup]="profileForm" *ngIf="profileForm">
                        <div class="row">
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Avatar</label>
                                    <input type="file" formControlName="avatar">
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email <span>*</span></label>
                                    <input type="email" class="form-control" formControlName="useremail" readonly>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span>*</span></label>
                                    <input type="text" formControlName="firstname" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span>*</span></label>
                                    <input type="text" formControlName="lastname" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control" formControlName="phonenumber">
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Something About You</label>
                                    <textarea cols="30" rows="8" class="form-control"></textarea>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn"
                                    (click)="updateProfile(this.profileForm.value)">Update Profile</button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Store Details Update -->
                <div class="dashboard-stats">
                    <form [formGroup]="updateStoreForm" *ngIf="updateStoreForm">
                        <div class="row">
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Logo <span>*</span></label>
                                    <input type="file">
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Name <span>*</span></label>
                                    <input type="text" formControlName="storename" class="form-control"  [(ngModel)]="storeDetails.storename" maxlength="32">
                                    <span class="chCount" *ngIf="storeDetails.storename.length!=32">{{ 0 + storeDetails.storename.length +
                                        '/32' }}</span>
                                    <span *ngIf="storeDetails.storename.length==32" class="redChCount">{{ 0 +
                                        storeDetails.storename.length + '/32'}}</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Link</label>
                                    <input type="text" formControlName="weburl" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Facebook Link</label>
                                    <input type="text" formControlName="facebooklink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Twitter Link</label>
                                    <input type="text" formControlName="twitterlink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Store Instagram Link</label>
                                    <input type="text" formControlName="instagramlink" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Store Description</label>
                                    <textarea cols="30" rows="8" formControlName="storedescription"
                                        [(ngModel)]="storeDetails.storedescription" maxlength="250" class="form-control"></textarea>
                                    <span class="chCount" *ngIf="storeDetails.storedescription.length!=250">{{ 0 + storeDetails.storedescription.length +
                                        '/250' }}</span>
                                    <span *ngIf="storeDetails.storedescription.length==250" class="redChCount">{{ 0 +
                                        storeDetails.storedescription.length + '/250'}}</span>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Address</label>
                                    <input type="text" formControlName="storeaddress" class="form-control">
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Area</label>
                                    <input type="text" formControlName="storearea" class="form-control">
                                </div>
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store City</label>
                                    <input type="text" formControlName="storecity" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store State</label>
                                    <input type="text" formControlName="storestate" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Country</label>
                                    <input type="text" formControlName="storecountry" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="">Store Pincode</label>
                                    <input type="text" formControlName="storezipcode" class="form-control">
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Store Locations</label>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe>
                                    <iframe src="https://maps.google.com/maps?q='+{{storeDetails.latitude}}+','+{{storeDetails.longitude}}+'&hl=es;z=14&amp;output=embed"></iframe>
                                    <iframe [src]="locationlink | safe"></iframe>
                                    <iframe width="300" height="170" frameborder="0" scrolling="no" marginheight="0"
                                        marginwidth="0" [src]="locationlink | safe">
                                    </iframe>
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" (click)="updateStore(this.updateStoreForm.value)"
                                    class="default-btn">Update Store</button>&nbsp;
                                <button class="default-btn" data-toggle="modal" data-target="#exampleModal">Delete My
                                    Store</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Store Delete</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Your Store will be deleted permenantly...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-danger" (click)="deleteStore()"
                    data-dismiss="modal">Delete</button>
            </div>
        </div>
    </div>
</div>