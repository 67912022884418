import { Component, OnInit } from '@angular/core';
import termsJson from 'src/assets/config.json/offerji.json'

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.scss']
})
export class TermsandconditionsComponent implements OnInit {

  termsJson : any = termsJson.TermsAndConditionsComponent

  constructor() { }

  ngOnInit(): void {
  }

  

}
