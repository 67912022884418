import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from 'src/app/services/api.service';
import bestDealsJson from 'src/assets/config.json/offerji.json';

@Component({
    selector: 'app-best-deals',
    templateUrl: './best-deals.component.html',
    styleUrls: ['./best-deals.component.scss']
})
export class BestDealsComponent implements OnInit {

    bestDealsJson : any = bestDealsJson.bestDealsComponent;
    Deals: any
    constructor(private api: ApiService) { }

    ngOnInit(): void {
        this.api.getDeals("", "", "").subscribe((result: any) => {
            this.Deals = result.success

        }, error => {
            if(error.error.code == 400){
                window.alert(error.error.message);
            }
        })
    }

    
    // singleDealsBox = [
    //     {
    //         image: `assets/img/deals/deals5.jpg`,
    //         trending: `Trending`,
    //         title: `Universal Studios`,
    //         location: `Orlando, Florida`,
    //         newPrice: `$50`,
    //         detailsLink: `deals-details`,
    //         ratingCount: `18`,
    //         ratingStar: [
    //             {
    //                 icon: `bx bxs-star`
    //             },
    //             {
    //                 icon: `bx bxs-star`
    //             },
    //             {
    //                 icon: `bx bxs-star`
    //             },
    //             {
    //                 icon: `bx bxs-star`
    //             },
    //             {
    //                 icon: `bx bxs-star`
    //             }
    //         ]
    //     }
    //    
    // ]
    dealsSlides: OwlOptions = {
        nav: true,
        margin: 30,
        loop: true,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    }

}