import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoreDetails } from 'src/app/models';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-dashboard-store',
    templateUrl: './dashboard-store.component.html',
    styleUrls: ['./dashboard-store.component.scss']
})
export class DashboardStoreComponent implements OnInit {
    storeid: string;

    locationlink: any;
    hasStore: boolean = false;
    updateStoreForm: FormGroup;
    storeDetails: StoreDetails = {};
    editStore: StoreDetails = {};
    accessuserid: string;
    isSuccess: boolean = false;
    isFailed: boolean = false;

    constructor(private api: ApiService, private sanitizer: DomSanitizer, private formbuilder: FormBuilder) { }

    ngOnInit(): void {
        this.accessuserid = localStorage.getItem("userid");

        this.hasStore = true;

        this.api.getStoreDetails(this.accessuserid).subscribe((result: any) => {
            this.storeDetails = result.success[0];
            console.log(this.storeDetails);
            this.storeid = this.storeDetails.storeid;
            console.log(result.success.length);
            if (result.success.length != 0) {
                this.hasStore = true;
                this.updatestoreform(this.storeDetails)
            } else {
                this.hasStore = false;
            }
            // this.locationlink = this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q="+this.storeDetails.latitude+","+this.storeDetails.longitude+"&z=zoom&output=embed")
            // this.locationlink = "https://maps.google.com/maps?q='+this.storeDetails.latitude+','+this.storeDetails.longitude+'&hl=es&z=14&amp;output=embed";
        })

    }

    updatestoreform(storeDetails: StoreDetails) {
        this.updateStoreForm = this.formbuilder.group({
            storename: [storeDetails.storename, Validators.required],
            weburl: [storeDetails.weburl,],
            facebooklink: [storeDetails.facebooklink,],
            twitterlink: [storeDetails.twitterlink,],
            instagramlink: [storeDetails.instagramlink,],
            storedescription: [storeDetails.storedescription, Validators.required],
            storeaddress: [storeDetails.storeaddress,],
            storearea: [storeDetails.storearea],
            storecity: [storeDetails.storecity],
            storestate: [storeDetails.storestate],
            storezipcode: [storeDetails.storezipcode],
            storecountry: [storeDetails.storecountry],
        })
        console.log(storeDetails.storename);

    }

    updateStore(data: any) {
        console.log(data.storename);

        this.editStore.storename = data.storename;
        this.editStore.weburl = data.weburl;
        this.editStore.facebooklink = data.facebooklink;
        this.editStore.twitterlink = data.twitterlink;
        this.editStore.instagramlink = data.instagramlink;
        this.editStore.storedescription = data.storedescription;
        this.editStore.storeaddress = data.storeaddress;
        this.editStore.storearea = data.storearea;
        this.editStore.storecity = data.storecity;
        this.editStore.storestate = data.storestate;
        this.editStore.storezipcode = data.storezipcode;
        this.editStore.storecountry = data.storecountry;
        this.editStore.storeid = this.storeid;

        this.api.updateStore(this.editStore).subscribe((result: any) => {
            if (result.code == 200) {
                Swal.fire({
                    toast: false,
                    icon: 'success',
                    title: "Store updated successfully",
                    showConfirmButton: false,
                    timerProgressBar: true,
                    width:'500px',
                    timer: 2000
                  })
                // window.alert("Store Updated Successfully!");
                // window.location.reload();
                this.isSuccess = true;
                this.isFailed = false;
            }
        }, error => {
            this.isSuccess = false;
            this.isFailed = true;
            if (error.error.code == 400) {
                // window.alert("Cannot update Store!");
            }
        })
    }

    pageTitle = [
        {
            bgImage: `assets/img/page-title/page-title4.jpg`,
            title: ``,
        }
    ]

    close() {
        window.location.reload();
        this.isSuccess = false;
        this.isFailed = false;
    }

}