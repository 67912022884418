import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-why-choose-us',
    templateUrl: './why-choose-us.component.html',
    styleUrls: ['./why-choose-us.component.scss']
})
export class WhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: ``,
            paragraph: ``
        }
    ]
    singleWhyChooseBox = [
        {
            icon: `bx bx-store`,
            title: `Merchant`,
            subTitle: `Merchant collaborate with our app and create offers on there products.`
        },
        {
            icon: `bx bx-globe`,
            title: `Offerji`,
            subTitle: `The created offeres are posted on the website and links of the same are created for media circulation.`
        },
        {
            icon: `bx bx-user`,
            title: `Customer`,
            subTitle: `The customers are made aware of the deals through social media and chat apps.`
        }
    ]
    whyChooseContent = [
        {
            title: `Discover Offerji Now`,
            buttonText: `Join Now`,
            buttonLink: `contact`
        }
    ]

}