import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import navbar from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    navbar : any = navbar.navbar
    Loggedin: boolean = false;
    isLoggedIn: any;

    constructor(private api: ApiService) { }

    ngOnInit(): void {
        if(localStorage.getItem("isLoggedin")=="true"){
            this.Loggedin = true;
        }
        this.api.loginSubject.subscribe((res) => {
            this.isLoggedIn = res.loggedIn
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}