<div class="page-title-area" *ngFor="let Content of termsJson.pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <h3>{{termsJson.title}}</h3>
            <ul style="margin-top: 40px;line-height: 50px;">
                <li><p>{{termsJson.terms1}}</p></li>
                <li><p>{{termsJson.terms2}}</p></li>
                <li><p>{{termsJson.terms3}}</p></li>
                <li><p>{{termsJson.terms4}}</p></li>
                <li><p>{{termsJson.terms5}}</p></li>
                <li><p>{{termsJson.terms6}}</p></li>
                <li><p>{{termsJson.terms7}}</p></li>
            </ul>
        </div>
    </div>
</div>