import { Component, OnInit } from '@angular/core';
import joinNowJson from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-join-now',
    templateUrl: './join-now.component.html',
    styleUrls: ['./join-now.component.scss']
})
export class JoinNowComponent implements OnInit {

    joinNowJson : any = joinNowJson.joinNowComponent

    constructor() { }

    ngOnInit(): void {
    }

   
}