import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import homepageJson from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {
homepageJson : any = homepageJson.homePage

    categories: any
    selectedcat: any
    locations: any;
    searchStore: FormGroup;
    constructor(private api: ApiService, private formbuilder: FormBuilder, private route: Router) { }

    ngOnInit(): void {

        this.searchStore = this.formbuilder.group({
            storelocation: ["",],
            storecategory: ["",],
        });
        this.api.getcategories().subscribe((result: any) => {
            this.categories = result.success
            this.selectedcat = result.success.slice(0, 5)
            console.log(this.selectedcat);
        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.code);
            }
        })

        this.api.getLocations().subscribe((result: any) => {
            this.locations = result.success;
        }, error => {
            if (error.error.code == 400) {
                window.alert(error.error.message);
            }
        });
    }

    // bannerWrapperContent = [
    //     {
    //         title: `Find your favorite stores offering deals here. `,
    //         paragraph: `Save every time you shop.........`
    //     }
    // ]
    // bannerWrapperImage = [
    //     {
    //         img: `assets/offerji/Vector-1_png_.png`
    //     }
    // ]

    searchStoreNavigate(data: any) {
        console.log(data);
        var url: string = "";
        if (data.storelocation == "") {
            data.storelocation = "All Locations";
        }
        if (data.storecategory == "") {
            data.storecategory = "All Categories";
        }
        url += "all-stores/" + data.storelocation + "/" + data.storecategory;
        this.route.navigate([url]);
    }

}