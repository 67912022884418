import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Stores } from 'src/app/modals';
import { ApiService } from 'src/app/services/api.service';
import storeDetailsJson from 'src/assets/config.json/offerji.json'
@Component({
  selector: 'app-store-details-page',
  templateUrl: './store-details-page.component.html',
  styleUrls: ['./store-details-page.component.scss']
})
export class StoreDetailsPageComponent implements OnInit {

  storeDetailsJson : any = storeDetailsJson.StoreDetailsPageComponent

  dealsGrid: number = 1;
  storeid: any = "";
  categoryid: string = "";
  storedetails: Stores;
  offers: any;
  offertype: string = "";

  constructor(private api: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.storeid = this.route.snapshot.paramMap.get("storeid")
    this.api.getStoreWithOutAlphabet(this.storeid).subscribe((result: any) => {
        console.log(result);
      this.storedetails = result.success[0];
      if (!(this.storedetails.facebooklink.includes("https://") || this.storedetails.facebooklink.includes("http://") || this.storedetails.facebooklink.includes("HTTPS://") || this.storedetails.facebooklink.includes("HTTP://"))) {
        if (this.storedetails.facebooklink != "") {
          this.storedetails.facebooklink = "//" + this.storedetails.facebooklink;
        }
      }
      if (!(this.storedetails.instagramlink.includes("https://") || this.storedetails.instagramlink.includes("http://") || this.storedetails.instagramlink.includes("HTTPS://") || this.storedetails.instagramlink.includes("HTTP://"))) {
        if (this.storedetails.instagramlink != "") {
          this.storedetails.instagramlink = "//" + this.storedetails.instagramlink;
        }
      }
      if (!(this.storedetails.twitterlink.includes("https://") || this.storedetails.twitterlink.includes("http://") || this.storedetails.twitterlink.includes("HTTPS://") || this.storedetails.twitterlink.includes("HTTP://"))) {
        if (this.storedetails.twitterlink != "") {
          this.storedetails.twitterlink = "//" + this.storedetails.twitterlink;
        }
      }
      if (!(this.storedetails.weburl.includes("https://") || this.storedetails.weburl.includes("http://") || this.storedetails.weburl.includes("HTTPS://") || this.storedetails.weburl.includes("HTTP://"))) {
        if (this.storedetails.weburl != "") {
          this.storedetails.weburl =  "//" + this.storedetails.weburl;
        }
      }
      if (!(this.storedetails.linkedinlink.includes("https://") || this.storedetails.linkedinlink.includes("http://") || this.storedetails.linkedinlink.includes("HTTPS://") || this.storedetails.linkedinlink.includes("HTTP://"))) {
        if (this.storedetails.linkedinlink != "") {
          this.storedetails.linkedinlink = "//" + this.storedetails.linkedinlink;
        }
      }
    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    })

    this.getDealsApi(this.categoryid, this.storeid, this.offertype);
  }

  getDealsApi(categoryid: any, storeid: any, offerType: any) {
    this.api.getDeals(categoryid, storeid, offerType).subscribe((result: any) => {
      this.offers = result.success;
      console.log(this.offers);

    }, error => {
      if (error.error.code == 400) {
        window.alert(error.error.message);
      }
    })
  }

  getOfferType(event: any) {
    this.offertype = event.target.value;
    console.log(this.offertype);

    this.getDealsApi(this.categoryid, this.storeid, this.offertype);
  }

  pageTitle = [
    {
      bgImage: `assets/img/page-title/page-title2.jpg`,
      title: ``
    }
  ]
 

}
