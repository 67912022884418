<div class="page-title-area" *ngFor="let Content of faqPageJson.pageTitle;"
    style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <accordion *ngFor="let Content of faqPageJson.faqAccordionBox;">
                <accordion-group heading="{{Content.question}}">
                    <p>{{Content.answer}}</p>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>