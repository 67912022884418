<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <h2><u>{{customerServiceJson.privacyPolicy}}</u></h2>
            <p class="last-updated">{{customerServiceJson.date}}</p>
            <!-- <h3>Shipping Times and Costs</h3> -->
            <p>{{customerServiceJson._1}} <b>{{customerServiceJson._1_}}</b>,
                {{customerServiceJson._1_1}}</p>
            <ul>
                <li>{{customerServiceJson._2}}</li>
                <li>{{customerServiceJson._3}}</li>
                <li>{{customerServiceJson._4}}</li>
            </ul>

            <p><strong>{{customerServiceJson._5}}</strong> {{customerServiceJson._6}} <a class="anchor"
                    href="mailto: support@saadruso.com">{{customerServiceJson._7}}</a>.</p>

            <h5>{{customerServiceJson._8}}</h5>

            <p class="summary-main">{{customerServiceJson._9}} <a class="anchor" href="privacypolicy/#table-of-contents"
                    class="btn" class="anchor">{{customerServiceJson._10}}</a> {{customerServiceJson._11}}</p>

            <p><strong>{{customerServiceJson._12}}</strong>{{customerServiceJson._13}}<a class="anchor"
                    href="privacypolicy/#personal-info">{{customerServiceJson._14}}</a> {{customerServiceJson._15}}</p>

            <p><strong>{{customerServiceJson._16}}</strong>{{customerServiceJson._17}}</p>

            <p><strong>{{customerServiceJson._18}}</strong>{{customerServiceJson._19}}</p>

            <p><strong>{{customerServiceJson._20}}</strong> {{customerServiceJson._21}}<a class="anchor"
                    href="privacypolicy/#process-info">{{customerServiceJson._22}}</a>
                {{customerServiceJson._23}}</p>

            <p>{{customerServiceJson._24}} <a class="anchor"
                    href="privacypolicy/#share-info">{{customerServiceJson._25}}</a> {{customerServiceJson._26}}.</p>

            <p><strong>{{customerServiceJson._27}}</strong> {{customerServiceJson._28}} <a class="anchor"
                    href="privacypolicy/#how-info-safe">{{customerServiceJson._29}}</a> {{customerServiceJson._30}}</p>

            <p><strong>{{customerServiceJson._31}}</strong> {{customerServiceJson._32}} <a class="anchor"
                    href="privacypolicy/#rights">{{customerServiceJson._33}}</a> {{customerServiceJson._34}}</p>

            <p><strong>{{customerServiceJson._35}}</strong> {{customerServiceJson._36}}</p>

            <p>{{customerServiceJson._37}}<a class="anchor"
                    href="privacypolicy/#info-we-collect">{{customerServiceJson._38}}</a>{{customerServiceJson._39}}</p>

            <section id="table-of-contents" style="margin-top: 60px;">
                <h4>{{customerServiceJson._40}}</h4>
                <ol>
                    <li><a href="privacypolicy/#personal-info" class="anchor">{{customerServiceJson.A}}</a></li>
                    <li><a href="privacypolicy/#process-info" class="anchor">{{customerServiceJson.B}}</a>
                    </li>
                    <li><a href="privacypolicy/#share-info" class="anchor">{{customerServiceJson.C}}</a></li>
                    <li><a href="privacypolicy/#use-cookies" class="anchor">{{customerServiceJson.D}}</a></li>
                    <li><a href="privacypolicy/#keep-info" class="anchor">{{customerServiceJson.E}}</a></li>
                    <li><a href="privacypolicy/#how-info-safe" class="anchor">{{customerServiceJson.F}}</a>
                    </li>
                    <li><a href="privacypolicy/#info-from-minors" class="anchor">{{customerServiceJson.G}}</a></li>
                    <li><a href="privacypolicy/#rights" class="anchor">{{customerServiceJson.H}}</a></li>
                    <li><a href="privacypolicy/#controlsfeatures" class="anchor">{{customerServiceJson.J}}</a>
                    </li>
                    <li><a href="privacypolicy/#california-rights" class="anchor">{{customerServiceJson.K}}</a></li>
                    <li><a href="privacypolicy/#updates-notice" class="anchor">{{customerServiceJson.L}}</a>
                    </li>
                    <li><a href="privacypolicy/#info-we-collect" class="anchor">{{customerServiceJson.M}}</a></li>
                    <li><a href="privacypolicy/#review-data-we-collect" class="anchor">{{customerServiceJson.N}}</a>
                    </li>
                </ol>

                <div class="queries">
                    <div class="querie" id="personal-info">
                        <h4>{{customerServiceJson.O}}</h4>
                        <p class="sub">{{customerServiceJson.P}}</p>
                        <p><strong>{{customerServiceJson.WhatInformation1}}</strong>WhatInformation2</p>
                        <p>{{customerServiceJson.WhatInformation3}}</p>
                        <p><strong>{{customerServiceJson.WhatInformation4}}</strong>
                            {{customerServiceJson.WhatInformation5}}</p>
                        <ul>
                            <li>{{customerServiceJson.name}}</li>
                            <li>{{customerServiceJson.phoneNumbers}}</li>
                            <li>{{customerServiceJson.emailAddress}}</li>
                            <li>{{customerServiceJson.mailAddress}}</li>
                            <li>{{customerServiceJson.userNames}}</li>
                            <li>{{customerServiceJson.passwords}}</li>
                            <li>{{customerServiceJson.contactPreference}}</li>
                            <li>{{customerServiceJson.contactOr}}</li>
                            <li>{{customerServiceJson.billingAddress}}</li>
                            <li>{{customerServiceJson.debitCard}}</li>
                        </ul>
                        <p><strong>{{customerServiceJson.WhatInformation6}}</strong>
                            {{customerServiceJson.WhatInformation7}}</p>
                        <p>{{customerServiceJson.WhatInformation8}}</p>

                        <p style="color: black;font-size: 16px;">{{customerServiceJson.WhatInformation9}}</p>
                        <p><strong>{{customerServiceJson.WhatInformation10}}</strong>
                            {{customerServiceJson.WhatInformation11}}</p>
                        <p>{{customerServiceJson.WhatInformation12}}</p>
                        <p>{{customerServiceJson.WhatInformation13}}</p>
                        <p>{{customerServiceJson.WhatInformation14}}</p>
                        <ul>
                            <li>
                                <p><strong>{{customerServiceJson.WhatInformation15}}</strong>
                                    {{customerServiceJson.WhatInformation16}}</p>
                            </li>
                            <li>
                                <p><strong>{{customerServiceJson.WhatInformation17}}</strong>
                                    {{customerServiceJson.WhatInformation18}}</p>
                            </li>
                            <li>
                                <p><strong>{{customerServiceJson.WhatInformation19}}</strong>
                                    {{customerServiceJson.WhatInformation20}}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="querie" id="process-info">
                        <h4>{{customerServiceJson.HowDoWe1}}</h4>

                        <p><strong>{{customerServiceJson.HowDoWe2}}</strong>{{customerServiceJson.HowDoWe3}}</p>
                        <p>{{customerServiceJson.HowDoWe4}} </p>
                        <ul>
                            <li><strong>{{customerServiceJson.HowDoWe5}}</strong> {{customerServiceJson.HowDoWe6}}</li>
                            <li><strong>{{customerServiceJson.HowDoWe7}}</strong> {{customerServiceJson.HowDoWe8}}</li>
                            <li><strong>{{customerServiceJson.HowDoWe9}}</strong>{{customerServiceJson.HowDoWe10}}</li>
                            <li><strong>{{customerServiceJson.HowDoWe12}}</strong> {{customerServiceJson.HowDoWe13}}
                            </li>
                            <li><strong>{{customerServiceJson.HowDoWe14}}</strong> {{customerServiceJson.HowDoWe15}}
                            </li>
                            <li><strong>{{customerServiceJson.HowDoWe16}}</strong> {{customerServiceJson.HowDoWe17}}
                            </li>
                        </ul>
                    </div>
                    <div class="querie" id="share-info">
                        <h4>{{customerServiceJson.Personal1}}</h4>

                        <p><strong>{{customerServiceJson.Personal2}}</strong>{{customerServiceJson.Personal3}}</p>
                        <p>{{customerServiceJson.Personal4}}</p>
                        <ul>
                            <li><strong>{{customerServiceJson.Personal5}}</strong> {{customerServiceJson.Personal6}}
                            </li>
                            <li><strong>{{customerServiceJson.Personal7}}</strong> {{customerServiceJson.Personal8}}
                            </li>

                        </ul>
                    </div>
                    <div class="querie" id="use-cookies">
                        <h4>{{customerServiceJson.Tracking1}}</h4>

                        <p><strong>{{customerServiceJson.Tracking2}}</strong>{{customerServiceJson.Tracking3}}</p>
                        <p>{{customerServiceJson.Tracking4}}</p>

                    </div>
                    <div class="querie" id="keep-info">
                        <h4>{{customerServiceJson.cookies1}}</h4>

                        <p><strong>{{customerServiceJson.cookies2}}</strong> {{customerServiceJson.cookies3}}</p>
                        <p>{{customerServiceJson.cookies4}}
                        </p>
                        <p>{{customerServiceJson.cookies5}}</p>

                    </div>
                    <div class="querie" id="how-info-safe">
                        <h4>{{customerServiceJson.information1}}</h4>

                        <p><strong>{{customerServiceJson.information2}}</strong>{{customerServiceJson.information3}}</p>
                        <p>{{customerServiceJson.information4}}</p>
                    </div>
                    <div class="querie" id="info-from-minors">
                        <h4>{{customerServiceJson.Minors1}}</h4>

                        <p><strong>{{customerServiceJson.Minors2}}</strong>{{customerServiceJson.Minors3}} </p>
                        <p>{{customerServiceJson.Minors4}} </p>
                    </div>
                    <div class="querie" id="rights">
                        <h4>{{customerServiceJson.privacy1}} </h4>

                        <p><strong>{{customerServiceJson.privacy2}}</strong> {{customerServiceJson.privacy3}}</p>
                        <p>{{customerServiceJson.privacy4}}
                            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                target="_blank">{{customerServiceJson.privacy5}}</a>.
                        </p>
                        <p>
                            {{customerServiceJson.privacy6}}
                        </p>
                        <p><strong>{{customerServiceJson.privacy7}}</strong>{{customerServiceJson.privacy8}}</p>
                        <p>{{customerServiceJson.privacy9}}</p>
                        <h5>{{customerServiceJson.privacy10}}</h5>
                        <p>{{customerServiceJson.privacy11}}</p>
                        <ul>
                            <li>{{customerServiceJson.privacy12}}</li>
                        </ul>
                        <p>{{customerServiceJson.privacy13}}</p>
                        <p>
                        </p>
                    </div>
                    <div class="querie" id="controlsfeatures">
                        <h4>{{customerServiceJson.notTrack1}}</h4>
                        <p>
                            {{customerServiceJson.notTrack2}} </p>

                    </div>
                    <div class="querie" id="california-rights">
                        <h4>{{customerServiceJson.resident1}}</h4>

                        <p><strong>{{customerServiceJson.resident2}}</strong>
                            {{customerServiceJson.resident3}}</p>
                        <p>{{customerServiceJson.resident4}}</p>
                        <p>{{customerServiceJson.resident5}}</p>

                    </div>
                    <div class="querie" id="updates-notice">
                        <h4>{{customerServiceJson.updates1}}</h4>

                        <p><strong>{{customerServiceJson.updates2}}</strong>
                            {{customerServiceJson.updates3}} </p>
                        <p>{{customerServiceJson.updates4}}</p>


                    </div>
                    <div class="querie" id="info-we-collect">
                        <h4>{{customerServiceJson.contactUs1}}</h4>

                        <p>>{{customerServiceJson.contactUs2}}</p>
                        <pre>
{{customerServiceJson.contactUs3}}
{{customerServiceJson.contactUs4}}
{{customerServiceJson.contactUs5}}
{{customerServiceJson.contactUs6}}
{{customerServiceJson.contactUs7}}
                        </pre>


                    </div>
                    <div class="querie" id="review-data-we-collect">
                        <h4>{{customerServiceJson.contactUs8}}</h4>



                        <p>{{customerServiceJson.contactUs9}}</p>


                    </div>
                </div>


            </section>


            <!-- End -->

        </div>
    </div>
</div>