import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreDetails } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss']
})
export class PreviewPageComponent implements OnInit {
  offerid: any;
  storeid: any;
  offers: any;
  offerEnds: any
  items: any;
  elem: any;
  storeDetails: StoreDetails;

  constructor(private route: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {

    this.elem = document.documentElement
    this.offerid = this.route.snapshot.paramMap.get('offerid');
    this.storeid = this.route.snapshot.paramMap.get('storeid');
    // this.storesapi()
    this.api.OffersPreview(this.storeid, this.offerid).subscribe((result: any) => {
      this.offers = result.success[0]
      console.log(this.offers);
      
      // this.offerEnds = result.success.EndsIn
      // this.storeDetails = result.success.Stores;

      // this.items = result.success.Items;


    }, error => {
      if(error.error.code == 400){
        window.alert(error.error.message);
      }
    })

    this.api.storeDetailsWithid(this.storeid).subscribe((result: any) => {
      // console.log(result);
      this.storeDetails = result.success[0];
    }, error => {
      if(error.error.code == 400){
        window.alert(error.error.message);
      }
    });

    this.api.getOfferItems(this.offerid).subscribe((result: any) => {
      this.items = result.success;
      // console.log(this.items);

    }, error => {
      if(error.error.code == 400){
        window.alert(error.error.message);
      }
    })
  }

}
