<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="dashboard-tabs-list">
            <ul>
                <li><a routerLink="/dashboard">Dashboard</a></li>
                <li><a routerLink="/dashboard-profile">Profile</a></li>
                <li><a routerLink="/dashboard-store">Store</a></li>
                <li><a routerLink="/dashboard-coupons">Coupons</a></li>
                <li><a routerLink="/dashboard-deals">Deals</a></li>
                <li><a routerLink="/dashboard-vouchers" class="active">Vouchers</a></li>
                <li><a routerLink="/dashboard-agents">Agents</a></li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="dashboard-profile-box">
                    <div class="image">
                        <img src="assets/img/user1.jpg" alt="image">
                        <span>Jason Smith</span>
                    </div>
                    <p>You have <strong>$19,321</strong> dollars left, <a routerLink="/">earn mores</a></p>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="dashboard-stats">
                    <form>
                        <p>In order to check for voucher input it into the field bellow and click on Verify</p>
                        <div class="form-group">
                            <label>Voucher Code <span>*</span></label>
                            <input type="text" class="form-control">
                        </div>
                        <button type="submit" class="default-btn">Verify</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>