<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let image of _album; let i=index;">
                <div class="single-gallery-item">
                    <img [src]="image.thumb" (click)="open(i)" />
                </div>
            </div>
        </div>
    </div>
</div>