<div class="dashboard-area ptb-100">
    <div class="container">
        <div class="dashboard-tabs-list">
            <ul>
                <li><a routerLink="/dashboard" class="active">Dashboard</a></li>
                <li><a routerLink="/dashboard-profile">Profile</a></li>
                <!-- <li><a routerLink="/dashboard-store">Store</a></li> -->
                <!-- <li><a routerLink="/dashboard-coupons">Coupons</a></li> -->
                <!-- <li><a routerLink="/dashboard-deals">Deals</a></li> -->
                <!-- <li><a routerLink="/dashboard-vouchers">Vouchers</a></li> -->
                <!-- <li><a routerLink="/dashboard-agents">Agents</a></li> -->
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-12" *ngIf="storeDetails">
                <div class="dashboard-profile-box">
                    <div class="image">
                        <img *ngIf="storeDetails.storelogo != ''" src="{{storeDetails.storelogo}}" alt="image">
                        <!-- <img *ngIf="gender == 'FEMALE'" src="assets/img/female_avatar.jpg" alt="image">
                        <img *ngIf="gender == 'MALE'" src="assets/img/male_avatar.png" alt="image"> -->
                        <!-- <span>{{fname}} {{lname}}</span> -->
                        <span class="overflow" tooltip="{{storeDetails.storename}}"> {{storeDetails.storename}}</span>

                    </div>
                    <!-- <p>Gender: {{gender}}</p> -->
                    <p>Phone Number: {{storeDetails.phonenumber}}</p>
                    <!-- <p>You have <strong>$19,321</strong> dollars left, <a routerLink="/">earn mores</a></p> -->
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="dashboard-stats">
                    <p>A quick view of your offers</p>
                    <div class="row">
                        <div class="col-lg-12" *ngIf="offers?.length==0">
                            <p style="color: red;">No offers to show</p>
                        </div>
                        <div class="col-lg-6 offer"
                            *ngFor="let offer of offers | paginate: { itemsPerPage: 4, currentPage: Grid }">
                            <div class="card">
                                <div class="card-body card-head-img">
                                    <img *ngIf="offer.offercover == '' " src="assets/offerji/image-cs-placeholder.png"
                                        alt="image">
                                    <img *ngIf="offer.offercover !== '' " src="{{offer.offercover}}" height="100%"
                                        width="100%" alt="">
                                </div>
                                <div class="card-footer card-footer-main">
                                    <p style=" white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;" tooltip="{{offer.offertitle}}">Offer Title:
                                        <span>{{offer.offertitle}}</span></p>
                                    <p style=" white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;" tooltip="{{offer.offerdescription}}">Offer Description:
                                        <span>{{offer.offerdescription}}</span></p>
                                    <div class="text-center">
                                        <button class="btn btn-danger"
                                            routerLink="/viewOffers/{{offer.offerid}}/{{offer.storeid}}">View
                                            Offer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-md-12">
        <div class="pagination-area text-center">
            <pagination-controls previousLabel="" nextLabel="" (pageChange)="Grid = $event"></pagination-controls>
        </div>
    </div>
</div>