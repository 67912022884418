<div class="page-title-area" *ngFor="let Content of contactPageJson.pageTitle;"
    style="background-image: url({{Content.bgImage}});background-color:var(--yellowColor);">
    <div class="container">
        <h1>{{Content.title}}</h1>
        <p>{{Content.paragraph}}</p>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="contactForm" class="formgroup" novalidate>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" name="name" type="text"
                                        class="form-control" formControlName="name" id="name" placeholder="{{contactPageJson.yourName}}">
                                        <small *ngIf="(contactForm.get('name')?.touched && contactForm.get('name')?.invalid) || submitted" class="text-danger">{{contactPageJson.yourNameErrorMsg}}</small>
                                    <!-- <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{
                                            name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input required  name="email" type="text" formControlName="email"
                                        class="form-control" id="email" placeholder="{{contactPageJson.email}}">
                                        <small *ngIf="(contactForm.get('email')?.touched && contactForm.get('email')?.invalid)  || submitted" class="text-danger">{{contactPageJson.emailErrorMsg}}</small>
                                    <!-- <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address
                                        is required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input  name="company" type="text" formControlName="company"
                                        class="form-control" id="company" placeholder="{{contactPageJson.companyName}}">
                                        <small *ngIf="contactForm.get('company')?.touched && contactForm.get('company')?.invalid" class="text-danger">{{contactPageJson.companyNameErrorMsg}}</small>
                                        
                                    <!-- <div class="alert alert-danger" *ngIf="company.touched && !company.valid">company
                                        name is required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="website" type="website" formControlName="website"
                                        class="form-control" id="website" placeholder="{{contactPageJson.website}}">
                                        <small *ngIf="contactForm.get('website')?.touched && contactForm.get('website')?.invalid" class="text-danger">{{contactPageJson.websiteErrorMsg}}</small>
                                    <!-- <div class="alert alert-danger" *ngIf="website.touched && !website.valid">website is
                                        required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message" name="message" id="message" cols="30"
                                        rows="6" class="form-control" placeholder="{{contactPageJson.message}}"></textarea>
                                        <small *ngIf="(contactForm.get('message')?.touched && contactForm.get('message')?.invalid)  || submitted" class="text-danger">{{contactPageJson.messageError}}"</small>
                                    <!-- <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is
                                        required.</div> -->
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button (click)="onSubmit(this.contactForm)" type="submit" class="default-btn"
                                    >{{contactPageJson.sendBtn}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="contact-info-list">

                    <div class="contact-info-box">
                        <h3>{{contactPageJson.address}}</h3>
                        <p>{{contactPageJson.companyAddress}}</p>
                    </div>
                    <div class="contact-info-box">
                        <h3>{{contactPageJson.Support}}</h3>
                        <p>{{contactPageJson.Mobile}} <a href="tel:+917416747480">{{contactPageJson.mobileNumber}}</a></p>
                        <p>{{contactPageJson.E_mail}} <a href="mailto:offerji@offerji.com">{{contactPageJson.Email}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd"></iframe> -->
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.7335652298084!2d78.32714231479763!3d17.472461888027677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93783f4298ef%3A0x861d168367e2108a!2sSaadruso!5e0!3m2!1sen!2sin!4v1659947096291!5m2!1sen!2sin"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>