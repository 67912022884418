<div class="container">
    <div class="popular-store-inner">
        <div class="row">
            <div class="col-lg-4 col-md-12" style="min-height: 200px;">
                <div class="popular-store-content"  style="background-image: url({{popularStoreJson.backgroundImg}});">
                    <div class="content">
                        <h2>{{popularStoreJson.title}}</h2>
                        <p>{{popularStoreJson.paragraph}}</p>
                        <a routerLink="/{{popularStoreJson.buttonLink}}" class="default-btn">{{popularStoreJson.buttonText}}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12 text-center" *ngIf="Allstores?.length ==0">
                <p style="color : red;">No Stores Found</p>
            </div>

            <div class="col-lg-8 col-md-12" *ngIf="Allstores">
                <div class="popular-store-list">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4 col-6 col-md-4" *ngFor="let Item of Allstores;">
                            <div class="item">
                                <a routerLink="/store-details/{{Item.storeid}}">
                                    <img [src]="Item.storelogo" style="height: 150px;width: auto;object-fit: contain;" alt="image">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>