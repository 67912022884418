import { Component, OnInit } from '@angular/core';
import footerData from 'src/assets/config.json/offerji.json'
@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    footerData: any = footerData.footer

    constructor() { }

    ngOnInit(): void {
    }

    offerjiYear: number = new Date().getFullYear();

}