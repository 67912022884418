<div class="page-title-area" *ngFor="let Content of storePageJson.pageTitle;"
    style="background-image: url({{Content.bgImage}});background-color: var(--redColor);background-size: contain;">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div>

<div class="popular-store-area ptb-100">
    <div class="container">
        <div class="filter-block">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <ul>

                        <li style="cursor:pointer" (click)="render({StoreAlphabeticKey:'All'})"><a
                                class="active">{{storePageJson.all}}</a></li>
                        <li style="cursor:pointer" (click)="render(data)" *ngFor="let data of storeAlpha">
                            <a
                                [class.active]="StoreAlphabeticKey == data.StoreAlphabeticKey">{{data.StoreAlphabeticKey}}</a>
                        </li>

                    </ul>
                </div>

                <div class="col-lg-4 col-md-5" style="display: flex;">
                    <div class="form-group category-select" style="margin-right: 10px;">
                        <label></label>
                        <select class="form-select" (change)="selectCategory($event)">
                            <option [selected]="'All Categories'==categoryname"> <i class='bx bx-category'>
                                    {{storePageJson.allCategories}}</i></option>
                            <!-- <option>others</option> -->
                            <option *ngFor="let data of categories" [selected]="data.category_name==categoryname">
                                {{data.category_name}}</option>
                        </select>
                    </div>
                    <div class="form-group category-select">
                        <label></label>
                        <select class="form-select" (change)="selectLocation($event)">
                            <option [selected]="'All Locations'==locationname"> <i class='bx bx-category'>
                                    {{storePageJson.allLocations}}</i></option>
                            <option *ngFor="let data of locations" [selected]="data.storecity==locationname">
                                {{data.storecity}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="popular-store-list">
            <div class="row">
                <div class="col-lg-3 col-sm-4 col-6 col-md-4" style="max-height: 400px;"
                    *ngFor="let store of Allstores ">
                    <div class="item">
                        <div class="card" style="height: 250px;">
                            <div class="card-body">
                                <div class="image" style="object-fit: scale-down;">
                                    <a routerLink="/store-details/{{store.storeid}}">
                                        <img style="height: 180px;" src="{{store.storelogo}}" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="text-center">
                                    <div class="text">
                                        <a tooltip="{{store.storename}}"
                                            style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                            routerLink="/store-details/{{store.storeid}}">
                                            <h6>{{store.storename}}</h6>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3 col-sm-4 col-6 col-md-4" *ngIf="Allstores?.length == 0">
                    <div class="item">
                        <p style="color: red;">{{storePageJson.noStoresFound}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>