import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactForm } from 'src/app/models';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import contactPageJson from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  contactPageJson : any = contactPageJson.contactPageComponent
    li: any;
    contact: ContactForm = {};
    contactForm: FormGroup;
    
    reseting :any;
  submitted: boolean = false;
    constructor(private api: ApiService, private formBuilder: FormBuilder) { }

    ngOnInit(): void {
      this.contactForm = this.formBuilder.group({
        name: ["", Validators.required],
        email: ["", Validators.required],
        company: [""],
        website: [""],
        message: ["", Validators.required],
      })
    }

   
    onSubmit(form: any){
      // if
      
      
      if(form.valid){
        console.log(form.valid);
        this.submitted = false;
        this.contact.name = form.value.name;
        
        this.contact.email = form.value.email;

        this.contact.company = form.value.company;
        
        this.contact.website = form.value.website;
        
        this.contact.message = form.value.message;
        console.log(true);
        
        this.api.contactus(this.contact).subscribe((result: any)=>{
          console.log("true");
          
          if(result.code == 200){
            Swal.fire({
              toast: false,
              icon: 'success',
              title: result.message,
              showConfirmButton: false,
              timerProgressBar: true,
              width:'500px',
              timer: 2000
            })
            this.contactForm.reset();
          }
        }, error => {
          if(error.error.code == 400){
            Swal.fire({
              toast: false,
              icon: 'error',
              title: error.error.message,
              showConfirmButton: false,
              timerProgressBar: true,
              width:'500px',
              timer: 2000
            })
          }
        })
      }else{
        
        this.submitted = true;
      }
      
      
       
    }
    
    


    // onSubmit(data: any) {
    //     const postData:JSON = <JSON><unknown>{
    //       "name": data.name,
    //       "email": data.email,
    //       "company": data.company,
    //       "website": data.website,
    //       "message": data.message,
    
    //     }
       
    //     // this.api.contact(postData).subscribe((result:any)=>
    //     // {
    //     //   this.li=result;
       
    //     //   console.log(this.li);
    //     //   if(this.li.status == "success"){
    //     //     window.location.reload();
    //     //        }
       
          
    //     // })
        
    
    //   }
  
}