<div class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <img src="{{storeDetails.storecoverpic}}" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h2>{{storeDetails.storename}}</h2>


                    <p>{{storeDetails.storedescription}}</p>

                    <div class="products-share">
                        <ul class="social">
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxs-location-plus'></i></a>
                            </li>
                            <li><a href="#" class="whatsapp" target="_blank"><i class='bx bxl-whatsapp'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bx-share-alt'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bx-qr-scan'></i></a></li>
                            <li><a href="#" class="facebook" target="_blank"><i class='bx bxs-home'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="products-area pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 single-deals-box " *ngFor="let offer of offers | paginate: { itemsPerPage: 6, currentPage: dealsGrid }">
                <div class="deals-image">
                    <a routerLink="" class="d-block">
                        <img src="{{offer.offercover}}" alt="image">
                    </a>

                </div>
                <div class="deals-content">


                    <!-- <h3>{{offer.offer_item_name}}</h3>
                    <div style="display: flex; ">
                        <h6 [innerHTML]="data.currencyicon"></h6>
                        <h6 style="text-decoration:line-through ;" class="location">{{data.offer_item_mrp}} </h6>
                    </div>

                    <div style="display: flex; ">
                        <h4 [innerHTML]="data.currencyicon"></h4>
                        <h4>{{data.offer_item_selling_price}}</h4>
                    </div> -->
                </div>
                <div class="box-footer">

                </div>
            </div>
        </div>
    </div>

</div>
<div style="padding-bottom: 20px;" class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <div class="pagination-area text-center">
        <pagination-controls previousLabel="" nextLabel="" (pageChange)="dealsGrid = $event"></pagination-controls>
    </div>
</div>

<!-- <div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-fullscreen" role="document">
        <div *ngIf="offers" class="modal-content">
            <div style="background-color:beige;" class="modal-header text-center ">
                <h2 style="text-transform: uppercase;" class="modal-title " id="exampleModalLabel"><img
                        src="{{storeDetails.storecoverpic}}" style="border-radius: 100%;" height="70px"
                        width="70px">&nbsp;{{storeDetails.storename}}
                </h2>
                <button type="button" class="btn-close" data-dismiss="modal" (click)="closefullscreen()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div style="overflow: hidden;background-color: beige;" class="modal-body">
                <div class='container-fluid center'>

                    <div class="carousel-inner">

                        <div class="carousel-item" [class.active]="isFirst"
                            *ngFor="let offer of items;first as isFirst">

                            <div class="row">
                                <div class="col-lg-7 card-column">
                                    <div class="card img-card">
                                        <img class="d-block w-100 carousel-image" src="{{offer.offer_item_image}}"
                                            alt="{{offer.offer_itemid}}">
                                    </div>
                                </div>
                                <div class="col-lg-5 data-column">
                                    <div class="card" style="margin-top: 30px;background: transparent;">
                                        <div class="card-header Offer-header">
                                            {{offer.offer_item_name}}
                                        </div>
                                        <div class="card-body">
                                            <h3>Price: <span id="price">{{offer.offer_item_selling_price}}</span>
                                            </h3>
                                            <img src="http://chart.apis.google.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl={{storeDetails.weburl}}"
                                                alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <owl-carousel-o [options]="homeSlides">
                    <ng-template carouselSlide *ngFor="let item of items;">
                        <div class="main-banner-box">
                            <div class="content">
                                <h3>{{Content.offer_item_name}}</h3>
                                <p>{{Content.offer_item_description}}</p>
                            </div> 
                            <div class="row caurosel">
                                <div class="col-sm-7">
                                    <div class="card caurosel-image">
                                        <img [src]="item.offer_item_image"
                                            style="box-shadow: -5px 5px 17px 5px rgb(1 1 1 / 50%);" class="caurosel-img"
                                            alt="Offer image not found">
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <div class="card text-card">

                                        <div class="card-body">
                                            <div class="Offer-header">
                                                <h2><a>Item Name:</a> {{item.offer_item_name}}</h2>
                                            </div>
                                            <h3>Price: <span id="price">{{item.offer_item_selling_price}}</span>
                                            </h3>
                                            <div class="storeimageqr">
                                                <img class="storeqr"
                                                    src="http://chart.apis.google.com/chart?chs=300x300&cht=qr&choe=UTF-8&chl={{storeDetails.weburl}}"
                                                    alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>

            </div>
        </div>

    </div>
</div> -->