<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget" >
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="{{footerData.footerImg}}" alt="image">
                    </a>
                    <p>{{footerData.paragraph}}</p>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>{{footerData.usefulLinks}} </h3>
                    <ul class="links-list">
                        <li><a routerLink="/privacypolicy">{{footerData.privacyPolicy}}</a></li>
                        <!-- <li><a routerLink="/customer-service">Return Policy</a></li> -->
                        <li><a routerLink="/termsandconditions">{{footerData.termsAndConditions}}</a></li>
                        <li><a routerLink="/how-it-works">{{footerData.howItWorks}}</a></li>
                    </ul>

                </div>
            </div>

            <div style="padding-top: 60px;" class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">

                    <ul class="links-list">
                        <li><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">{{footerData.faq}}</a></li>
                        <!-- <li ><a routerLink="/blog-grid" href="javascript:void(0)" class="nav-link">Blog </a> -->

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>{{footerData.contactInfo}} </h3>
                    <ul class="footer-contact-info">
                        <li>{{footerData.address1}}</li>
                        <li>{{footerData.address2}}</li>
                        <!-- <li>Email: <a href="offerji@offerji.com">offerji@offerji.com</a></li>
                        <li>Phone: <a href="tel:+44587154756">+91 987 654 3210</a></li> -->
                        <li>{{footerData.address3}}</li>
                        <li>{{footerData.addressEmail}} <a href="mailto: offerji@offerji.com">{{footerData.addressEmail1}}</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>


</footer>