import { Component, OnInit } from '@angular/core';
import faqPageJson from 'src/assets/config.json/offerji.json'

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
    
    faqPageJson : any = faqPageJson.faqPageComponent

    constructor() { }

    ngOnInit(): void {
    }

    
}