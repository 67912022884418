<div class="navbar-area navbar-style-two" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="{{navbar.navbarLogo}}" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item" ><a  routerLink="/" class=" nav-link">{{navbar.home}}</a>
                    
                    </li>
                    <li *ngIf="isLoggedIn" class="nav-item"><a routerLink="/dashboard" class=" nav-link">{{navbar.dashboard}}</a>
                    
                    </li>
                    <li class="nav-item" ><a  routerLink="/aboutUs" class=" nav-link">{{navbar.aboutUs}}</a>
                    
                    </li>

                    <li class="nav-item" ><a  routerLink="/how-it-works" class=" nav-link">{{navbar.HowItWorks}}</a>
                    
                    </li>
                    
                    <li class="nav-item"><a routerLink="/all-stores" href="javascript:void(0)" class=" nav-link">{{navbar.stores}}</a>
                        
                    </li>
                    <li class="nav-item"><a routerLink="/categories" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{navbar.Categories}}</a></li>
                           
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Pages</a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/how-it-works" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">How It Works</a></li>
                            <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                     <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>
                            <li class="nav-item"><a routerLink="/customer-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customer Service</a></li> 
                             <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li> 
                             <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li> 
                            <li class="nav-item"><a routerLink="/blog-grid" href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-right'></i></a>
                               
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">My Account <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Profile</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-store" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Store</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-coupons" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Coupons</a></li>
                                    <li class="nav-item"><a routerLink="/dashboard-deals" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Deals</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li> -->
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{navbar.contact}}</a></li>
                </ul>

            </div>
        </nav>
    </div>
</div>