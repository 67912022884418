import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Stores } from 'src/app/modals';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-view-store',
  templateUrl: './view-store.component.html',
  styleUrls: ['./view-store.component.scss']
})
export class ViewStoreComponent implements OnInit {
  storeid: string;
  storeDetails: Stores = {};
  offers: any;
  dealsGrid: number = 1;

  constructor(private route: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {
    this.storeid = this.route.snapshot.paramMap.get('storeid');
    this.api.getStoreDetails(this.storeid).subscribe((result: any) => {
      this.storeDetails = result.success[0].Stores[0];
      // console.log(result);
    }, error => {
      if(error.error.code == 400){
        window.alert(error.error.message);
      }
    })

    this.api.storeDetailsWithid(this.storeid).subscribe((result: any) => {
      this.offers = result.success;
      console.log(this.offers);
    }, error => {
      if(error.error.code == 400){
        window.alert(error.error.message);
      }
    })
  }

}
